import React, { useCallback, useState } from 'react';
import {Box, Button, Container, Grid} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useCreateCostItemMutation, useUpdateCostItemMutation, useRemoveCostItemMutation } from '../generated/graphql';
import NotificationPopup from '../component/NotificationPopup';
import MutationPopupButton from '../component/MutationPopupButton';
import { InputType } from '../common/FormRenderer';
import {omit, reduce} from 'lodash';
import ConfirmButton from "../component/ConfirmButton";
import {DataGridLocale} from "../common/Utils";

const CostItemPage = ({ order, canEdit=true }) => {
    const [createCostItem] = useCreateCostItemMutation();
    const [updateCostItem] = useUpdateCostItemMutation();
    const [removeCostItem] = useRemoveCostItemMutation();

    const onCreate = useCallback(
        async (costItem, { resetForm }, setOpen) => {
            try {
                await createCostItem({
                    variables: {
                        costItem: { ...costItem, orderId: order.id },
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success('新增完成');
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createCostItem, order.id]
    );

    const onUpdate = useCallback(
        async (costItem, { resetForm }, setOpen) => {
            try {
                await updateCostItem({
                    variables: {
                        id: costItem.id,
                        costItem: omit(costItem, ['id']),
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success('修改完成');
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateCostItem]
    );

    const onRemove = useCallback(
        async (id) => {
            try {
                await removeCostItem({
                    variables: {
                        id: id,
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success('刪除完成');
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            }
        },
        [removeCostItem]
    );

    const [filterModel, setFilterModel] = useState<any>({ items: [] });

    const columns = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'name', headerName: '項目', width: 100 },
        { field: 'content', headerName: '內容', flex: 1 },
        { field: 'amount', headerName: '成本', type: InputType.number, width: 120 },
        { field: 'actualAmount', headerName: '實際成本', type: InputType.number, width: 120 },
        {
            field: 'edit',
            headerName: '修改',
            sortable: false,
            disableColumnMenu: true,
            hide: !canEdit,
            renderCell: (params: any) => (
                <MutationPopupButton
                    title={'修改'}
                    columns={[
                        { name: 'id', label: 'ID', value: params?.row.id, type: InputType.hidden },
                        { name: 'name', label: '名稱', value: params.row.name },
                        { name: 'content', label: '內容', value: params.row.content },
                        { name: 'amount', label: '成本', value: params.row.amount, type: InputType.number },
                    ]}
                    onSubmit={onUpdate}
                    submitTitle={'修改'}
                />
            ),
        },
        {
            field: 'editActualAmount',
            headerName: '填寫實際成本',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => (
                <MutationPopupButton
                    title={'填寫'}
                    columns={[
                        { name: 'id', label: 'ID', value: params?.row.id, type: InputType.hidden },
                        { name: 'actualAmount', label: '實際成本', value: params.row.actualAmount, type: InputType.number },
                    ]}
                    onSubmit={onUpdate}
                    submitTitle={'送出'}
                />
            ),
        },
        {
            field: 'remove',
            headerName: '刪除',
            sortable: false,
            disableColumnMenu: true,
            hide: !canEdit,
            renderCell: (params: any) => (
                <ConfirmButton onConfirm={() => onRemove(params?.row.id)}>
                    刪除
                </ConfirmButton>
            ),
        },
    ];
    const totolAmount = parseInt(reduce(order.productItems, ((acc,productItem)=>acc+productItem.amount),order.totalAmount ?? 0));

    const totalCost = order.costItems.reduce((total, costItem) => total+costItem.amount, 0);
    const grossProfit = totolAmount - totalCost;
    const grossProfitRate = totolAmount ? ((grossProfit/totolAmount)*100).toFixed(2) : 0;

    const actualCost = order.costItems.reduce((total, costItem) => total+(costItem.actualAmount ?? 0), 0);
    const actualGrossProfit = totolAmount - actualCost;
    const actualGrossProfitRate = totolAmount ? ((actualGrossProfit/totolAmount)*100).toFixed(2) : 0;

    // 自定义底部渲染内容
    const renderFooter = () => {
        return (
            <div>
                <Grid container justifyContent="flex-end" alignItems="center" fontSize={24}>
                    專案總金額：{totolAmount.toLocaleString() ?? 0}
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="center" height="100%" fontSize={24} spacing={2}>
                    <Grid item>
                        預估成本：{totalCost?.toLocaleString()}
                    </Grid>
                    <Grid item>
                        預估毛利：{grossProfit?.toLocaleString()}
                    </Grid>
                    <Grid item>
                        毛利率：{grossProfitRate}%
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="center" height="100%" fontSize={24} spacing={2}>
                    <Grid item>
                        實際成本：{actualCost?.toLocaleString()}
                    </Grid>
                    <Grid item>
                        實際毛利：{actualGrossProfit?.toLocaleString()}
                    </Grid>
                    <Grid item>
                        毛利率：{actualGrossProfitRate}%
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <div>
            {canEdit && <MutationPopupButton
                title={'新增成本'}
                columns={[
                    { name: 'name', label: '項目' },
                    { name: 'content', label: '內容' },
                    { name: 'amount', label: '成本', type: InputType.number },
                    { name: 'actualAmount', label: '實際成本', type: InputType.number },
                ]}
                onSubmit={onCreate}
                submitTitle={'新增'}
            />}

            <Box width={'100%'} style={{ marginTop: '30px' }}>
                <DataGridPro
                    filterModel={filterModel}
                    onFilterModelChange={(filterModel) => {
                        setFilterModel(filterModel);
                    }}
                    localeText={DataGridLocale}
                    style={{ borderTopLeftRadius: 'unset' }}
                    autoHeight={true}
                    loading={false}
                    rows={order?.costItems ?? []}
                    columns={columns}
                    components={{Footer: renderFooter}}
                />
            </Box>
        </div>
    );
};

export default CostItemPage;
