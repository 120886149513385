import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import FormRenderer, { InputType } from "../common/FormRenderer";
import { Formik } from "formik";
import React, { useState, useCallback } from "react";
import { format } from "date-fns";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import {isEqual, reduce} from "lodash";
import ConfirmButton from "./ConfirmButton"


export const getModifiedValues = (values, initialValues, submitTitle) => {
    if (submitTitle == '新增')
        return values;
    let modifiedValues = {};
    if (values) {
        Object.entries(values).forEach((entry) => {
            let key = entry[0];
            let value = entry[1];
            if (key == 'id' || !isEqual(value,initialValues[key])) {
                modifiedValues[key] = value;
            }
        });
    }
    return modifiedValues;
};

export default ({
    columns,
    onSubmit,
    onRemove = null,
}: {
    columns: any;
    onSubmit: any;
    onRemove?: any;

}) => {
    const [changedValues, setChangedValues] = useState({});
    const initialValues = reduce(
        columns,
        (acc, column) => {
            return { ...acc, [column.name]: column.value };
        },
        {}
    )
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, bla) => {
                onSubmit(getModifiedValues(values, initialValues, ''), bla)
            }}
        >
            {({ handleChange, resetForm, handleSubmit, values }) => {
                columns.map((column)=> {
                    if ( 'onChange' in column) {
                        column.onChange(values[column.name])
                    }
                })
                return (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <FormRenderer
                                values={values}
                                def={columns}
                                onChange={handleChange}
                            />
                        </DialogContent>
                        <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                            {onRemove && (
                                <ConfirmButton
                                    onConfirm={onRemove}

                                >
                                    刪除
                                </ConfirmButton>
                            )}
                            <div style={{ flex: "1 0 0" }} />
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                            >
                                修改
                            </Button>
                            {/*<Button*/}
                            {/*    variant={"contained"}*/}
                            {/*    color={"secondary"}*/}
                            {/*    onClick={() => {*/}
                            {/*        // setOpen(false);*/}
                            {/*        resetForm();*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    取消*/}
                            {/*</Button>*/}
                        </DialogActions>
                    </form>
                );
            }}
        </Formik>
    );
};
