import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
const useStyles = makeStyles(() => ({
    title: {
        marginBottom: 10,
        textAlign: "center",
        fontSize: 24,
    },
    label: {
        margin: 10,
        minWidth: 100,
        fontSize: 18,
    },
}));


export default ({ contact }) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles();
    return (
        <Box>
            <Dialog open={open} onClose={()=>setOpen(false)}>
                <DialogTitle className={classes.title}>
                    聯絡人資料
                </DialogTitle>
                <DialogContent className={classes.label}>
                    姓名: {contact?.name}
                </DialogContent>
                <DialogContent className={classes.label}>
                    電話: {contact?.phone}
                </DialogContent>
                <DialogContent className={classes.label}>
                    Email: {contact?.email}
                </DialogContent>
                <Button onClick={() => setOpen(false)} className={classes.label}>
                    關閉
                </Button>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => {setOpen(true)}}
            >
                {contact?.name}
            </Button>
        </Box>
    );
};
