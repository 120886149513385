import React, { useCallback, useState } from 'react';
import { useCreateTermMutation, useUpdateTermMutation, useRemoveTermMutation, useGetTermsQuery } from '../generated/graphql';
import { Box, Container } from '@mui/material';
import MutationPopupButton from '../component/MutationPopupButton';
import { InputType } from '../common/FormRenderer';
import NotificationPopup from '../component/NotificationPopup';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGridLocale } from '../common/Utils';
import {omit} from "lodash";

const TermPage = () => {
    const { data: termsData, loading: termsLoading, error: termsError } = useGetTermsQuery();
    const [createTerm] = useCreateTermMutation();
    const [updateTerm] = useUpdateTermMutation();
    const [removeTerm] = useRemoveTermMutation();
    const [filterModel, setFilterModel] = useState<any>({ items: [] });

    const onCreate = useCallback(
        async (term, { resetForm }, setOpen) => {
            try {
                await createTerm({
                    variables: { term },
                    refetchQueries: ["getTerms"],
                });
                NotificationPopup.success('新增完成');
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createTerm]
    );

    const onUpdate = useCallback(
        async (term, { resetForm }, setOpen) => {
            try {
                await updateTerm({
                    variables: { id: term.id,
                        term: omit(term, ['id']) },
                    refetchQueries: ["getTerms"],
                });
                NotificationPopup.success('修改完成');
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateTerm]
    );
    const onRemove = useCallback(
        async (termId) => {
            try {
                await removeTerm({
                    variables: {
                        id: termId,
                    },
                    refetchQueries: ["getTerms"],
                });
                NotificationPopup.success(`刪除完成`);
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            }
        },
        [removeTerm]
    );


    const columns = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'name', headerName: '名稱' },
        { field: 'projectTerms', headerName: '專案條款', width: 250 },
        { field: 'termsConditions', headerName: '約定條款', flex: 1 },
        {
            field: 'edit',
            headerName: '修改',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => (
                <MutationPopupButton
                    title={'修改'}
                    columns={[
                        { name: 'id', label: 'ID', value: params?.row.id, type: InputType.hidden },
                        { name: 'name', label: '名稱', value: params.row.name },
                        { name: 'projectTerms', label: '專案條款', value: params.row.projectTerms, multiline: true },
                        {
                            name: 'termsConditions',
                            label: '約定條款',
                            value: params.row.termsConditions,
                            multiline: true,
                        },
                    ]}
                    onSubmit={onUpdate}
                    onRemove={()=>onRemove(params?.row.id)}
                    submitTitle={'修改'}
                />
            ),
        },
    ];

    return (
        <Container maxWidth={false} style={{ padding: '30px' }}>
            <MutationPopupButton
                title={'新增條款'}
                columns={[
                    { name: 'name', label: '名稱' },
                    { name: 'projectTerms', label: '專案條款', multiline: true },
                    { name: 'termsConditions', label: '約定條款', multiline: true },
                ]}
                onSubmit={onCreate}
                submitTitle={'新增'}
            />

            <Box width={'100%'} style={{ marginTop: '30px' }}>
                <DataGridPro
                    filterModel={filterModel}
                    onFilterModelChange={setFilterModel}
                    style={{ borderTopLeftRadius: 'unset' }}
                    localeText={DataGridLocale}
                    autoHeight={true}
                    loading={false}
                    rows={termsData?.terms ?? []}
                    columns={columns}
                />
            </Box>
        </Container>
    );
};

export default TermPage;
