import React, {useCallback} from "react";
import {InputType} from "../common/FormRenderer";
import {Role, useUpdateOrderMutation} from "../generated/graphql";
import {hasRole, selectMapFn} from "../common/Utils";
import {useUser} from "../auth/Auth";
import NotificationPopup from "../component/NotificationPopup";
import CreateView from "../component/CreateView";
import {format} from "date-fns";

export default ({ order }) => {
    const user = useUser();
    const [updateOrder] = useUpdateOrderMutation({
        refetchQueries: ["getOrder", "getOrders"],
    });
    const columns =
        [
            {
                name: "name",
                label: "專案名稱",
                value: order.name,
            },
            {
                name: "startDate",
                label: "執行開始日期",
                required: true,
                type: InputType.date,
                value: order.startDate ? format(new Date(order.startDate), 'yyyy-MM-dd') : null,
            },
            {
                name: "endDate",
                label: "執行結束日期",
                required: true,
                type: InputType.date,
                value: order.endDate ? format(new Date(order.endDate), 'yyyy-MM-dd') : null,
            },
            {
                name: "signDate",
                label: "委刊單日期",
                required: true,
                type: InputType.date,
                value: order.signDate ? format(new Date(order.signDate), 'yyyy-MM-dd') : null,
            },
            {
                name: "contactId",
                label: "聯絡人",
                type: InputType.select,
                options: order.customer?.contacts?.map(selectMapFn) ?? [],
                value: order.contact?.id,
            },
            {
                name: "version",
                label: "版本",
                value: order.version,
                type: InputType.number,
            },
            ...(hasRole(user, [Role.Admin]) ? [{
                name: "projectTerms",
                label: "專案條款",
                value: order.projectTerms,
                type: InputType.textarea,
            },
            {
                name: "termsConditions",
                label: "約定條款",
                value: order.termsConditions,
                type: InputType.textarea,
            }] : []),
            {
                name: "backup",
                label: "附註",
            }
        ]
    const onSubmit = useCallback(
        async (updatedOrder, { resetForm }, setOpen) => {
            try {
                await updateOrder({
                    variables: {
                        order: updatedOrder,
                        id: order.id,
                    },
                });
                NotificationPopup.success(`修改完成`);
                setOpen(false);
            } catch (e) {
                NotificationPopup.error(`修改委刊單發生問題: ${e.message}`);
                console.error(e);
            }
        },
        [updateOrder, user]
    );

    return (
        <CreateView
            title={"修改委刊單"}
            columns={columns}
            onSubmit={onSubmit}
            submitTitle={"修改"}
        />
    );
};
