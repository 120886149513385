import {GridLocaleText, GridValueFormatterParams} from "@mui/x-data-grid-pro";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import NotificationPopup from "../component/NotificationPopup";
import {getAccessToken, useUser} from "../auth/Auth";
import { saveAs } from "file-saver";
import {format} from "date-fns";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {Role, User} from "../generated/graphql";

export const DataGridLocale:Partial<GridLocaleText> = {
    // booleanCellFalseLabel: "",
    // booleanCellTrueLabel: "",
    // checkboxSelectionHeaderName: "",
    // filterValueAny: "",
    // filterValueFalse: "",
    // filterValueTrue: "",
    // footerRowSelected(count: number): React.ReactNode
    // {
    //     return undefined;
    // },
    // footerTotalRows: undefined,
    // noResultsOverlayLabel: "",
    // toolbarColumns: undefined,
    // toolbarColumnsLabel: "",
    // toolbarDensity: undefined,
    // toolbarDensityComfortable: "",
    // toolbarDensityCompact: "",
    // toolbarDensityLabel: "",
    // toolbarDensityStandard: "",
    toolbarExport: "輸出報表",
    toolbarExportCSV: "下載CSV檔",
    // Root
    // rootGridLabel: "表單",
    noRowsLabel: "沒有任何內容",
    errorOverlayDefaultLabel: "錯誤發生",
    // Column menu text
    columnMenuLabel: "Menu",
    columnMenuShowColumns: "顯示",
    columnMenuFilter: "篩選",
    columnMenuHideColumn: "隱藏",
    columnMenuUnsort: "不指定排序",
    columnMenuSortAsc: "由小到大",
    columnMenuSortDesc: "由大到小",
    // Columns panel text
    columnsPanelTextFieldLabel: "尋找欄位",
    columnsPanelTextFieldPlaceholder: "欄位名稱",
    columnsPanelDragIconLabel: "重新排序欄位",
    columnsPanelShowAllButton: "顯示全部",
    columnsPanelHideAllButton: "隱藏全部",
    // Filter panel text
    filterPanelAddFilter: "增加篩選",
    filterPanelDeleteIconLabel: "刪除",
    filterPanelOperators: "篩選條件",
    filterPanelOperatorAnd: "And",
    filterPanelOperatorOr: "Or",
    filterPanelColumns: "欄位",
    filterPanelInputLabel: "值",
    filterPanelInputPlaceholder: "輸入值",
    // Filter operators text
    filterOperatorContains: "包含",
    filterOperatorEquals: "等於",
    filterOperatorStartsWith: "開頭是",
    filterOperatorEndsWith: "結尾是",
    filterOperatorIs: "等於",
    filterOperatorNot: "不等於",
    filterOperatorIsAnyOf: "屬於任何",
    filterOperatorAfter: "晚於",
    filterOperatorOnOrAfter: "晚於(包含)",
    filterOperatorBefore: "早於",
    filterOperatorOnOrBefore: "早於(包含)",
    filterOperatorIsEmpty: "等於空值",
    filterOperatorIsNotEmpty: "不是空值",

    // Filters toolbar button text
    toolbarFilters: "篩選",
    toolbarFiltersLabel: "顯示篩選",
    toolbarFiltersTooltipHide: "隱藏篩選",
    toolbarFiltersTooltipShow: "顯示篩選",
    toolbarFiltersTooltipActive: (count) => `${count}個篩選條件`,
    // Column header text
    columnHeaderFiltersTooltipActive: (count) => `${count}個篩選條件`,
    columnHeaderFiltersLabel: "顯示篩選",
    columnHeaderSortIconLabel: "排序",
    pinToLeft: "釘選至左邊",
    pinToRight: "釘選至右邊",
    unpin: "取消釘選",

    noResultsOverlayLabel:"沒有資料",
};

export const useTabIndex = (): [number, (n: number) => void] => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setIndex(
            searchParams.get("index")
                ? parseInt(searchParams.get("index") as string)
                : 0
        );
    }, []);
    useEffect(() => {
        navigate({
            search: `?index=${index}`,
        }, {replace:true});
    }, [index])
    return [index, setIndex];
};

export const selectMapFn = ({ id, name }: { id: any; name?: any }) => ({
    value: id,
    label: name,
});



export const saveFile = async (uriPart, filename) => {
    const token = await getAccessToken();
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URI}${uriPart}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    if(!res.ok)
    {
        const json = await res.json();
        NotificationPopup.error(`下載遇到問題. ${json.message || JSON.stringify(json)}`);
        return;
    }
    const blob = await res.blob();
    saveAs(blob, filename);
};

export const previewPdf = async (uriPart, fileName) => {
    const token = await getAccessToken();
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URI}${uriPart}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const blob = await res.blob();
    const file = new Blob([blob], {
        type: "application/pdf",
    });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
};

export const dateValueFormatter = (params: GridValueFormatterParams) => {

    return params?.value ? format(new Date(params?.value as string), "yyyy-MM-dd") : "";
};
export const getErrorNotifyElement = (text) => {
    return <div>
        <ErrorOutlineIcon style={{
            color: 'red',
            position: 'absolute',
            top: '0px',
            left: '0', }} />
        {text}
    </div>
}
export const HasRole = ({roles,children}:{roles:Role[],children:any})=>
{
    const user = useUser();
    if (hasRole(user, roles))
    {
        return children;
    }
    else
    {
        return null;
    }
}

export function hasRole(user: Partial<User>|undefined, roles: Role[]) {
    return user && user.roles?.find((role)=>roles.includes(role))
}