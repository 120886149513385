import React, { useCallback } from "react";
import {
    useGetCustomerQuery,
    useUpdateCustomerMutation,
    useRemoveCustomerMutation, CustomerType,
} from "../generated/graphql";
import EditView, { getModifiedValues } from "../component/EditView";
import NotificationPopup from "../component/NotificationPopup";
import {AppBar, Tab, Tabs} from "@mui/material";
import Page from "../component/Page";
import {useTabIndex} from "../common/Utils";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import TabPanel from "../common/TabPanel";
import {useParams} from "react-router";
import ContactPage from "../contacts/ContactPage";
import {InputType} from "../common/FormRenderer";
import {CustomerSourceList, CustomerTypeList, IndustryTypeList} from "../common/Constant";
import {BrandPage} from "../brands/BrandPage";

const CustomerDetail = () => {
    const { id: idString } = useParams<string>();
    const id = idString ? parseInt(idString) : 0;

    const [tabIndex, setTabIndex] = useTabIndex();
    const { loading, error, data } = useGetCustomerQuery({
        variables: { id },
    });

    const [updateCustomer] = useUpdateCustomerMutation();
    const [removeCustomer] = useRemoveCustomerMutation();

    const onUpdate = useCallback(
        async (values, { resetForm }) => {
            try {
                const modifiedValues = getModifiedValues(
                    values,
                    data?.customer,
                    "修改"
                );
                await updateCustomer({
                    variables: {
                        id,
                        customer: modifiedValues,
                    },
                    refetchQueries: ["getCustomer", "getCustomers"]
                });
                NotificationPopup.success("修改成功");
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
                console.error(e);
            }
        },
        [updateCustomer, data]
    );

    const onRemove = useCallback(async () => {
        try {
            await removeCustomer({
                variables: {
                    id,
                },
            });
            NotificationPopup.success("刪除成功");
        } catch (e) {
            NotificationPopup.error(`發生問題：${e.message}`);
            console.error(e);
        }
    }, [removeCustomer, id]);

    const columns = [
        {
            name: "name",
            label: "名稱",
            value: data?.customer?.name,
        },
        {
            name: "address",
            label: "地址",
            value: data?.customer?.address,
        },
        {
            name: "backup",
            label: "備註",
            value: data?.customer?.backup,
        },
        {
            name: "industryTypes",
            label: "產業類別",
            type: InputType.select,
            value: data?.customer?.industryTypes,
            options: IndustryTypeList,
            isMulti: true,
        },
        {
            name: "phone",
            label: "電話",
            value: data?.customer?.phone,
        },
        {
            name: "taxId",
            label: "統一編號",
            value: data?.customer?.taxId,
        },
        {
            name: "type",
            label: "客戶類型",
            value: data?.customer?.type,
            type: InputType.select,
            options: CustomerTypeList,
        },
        {
            name: "source",
            label: "客戶來源",
            value: data?.customer?.source,
            type: InputType.select,
            options: CustomerSourceList,
        },
        {
            name: "bankAccount",
            label: "銀行帳戶",
            value: data?.customer?.bankAccount,
        },
    ];


    return (

        <Page title={"customer detail page"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) => {
                        setTabIndex(newValue);
                    }}
                >
                    <Tab label="客戶"/>
                    <Tab label="聯絡人"/>
                    <Tab label="品牌"/>
                </Tabs>
            </AppBar>

            <DefaultQueryHandler
                error={error}
                loading={loading}
            >
                <TabPanel value={tabIndex} index={0}>
                    <EditView
                        columns={columns}
                        onSubmit={onUpdate}
                        onRemove={onRemove}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <ContactPage customer={data?.customer}></ContactPage>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <BrandPage customer={data?.customer}></BrandPage>
                </TabPanel>
            </DefaultQueryHandler>
        </Page>
    );
};

export default CustomerDetail;
