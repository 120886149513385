import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {DataGridLocale, saveFile} from "../common/Utils";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {format} from "date-fns";

export default ({order}) => {
    const [open, setOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth="lg">
                <DialogTitle style={{ backgroundColor: "#DDD" }}>
                    <Typography variant="h4" align="center">
                        下載時程表
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DataGridPro
                        autoHeight
                        sortModel={[{field: "startDate", sort: 'asc'}]}
                        rows={order.orderSchedules}
                        localeText={DataGridLocale}
                        checkboxSelection
                        disableSelectionOnClick
                        selectionModel={selectedRows}
                        onSelectionModelChange={(newSelection) => {
                            setSelectedRows(newSelection);
                        }}
                        columns={[
                            {field: 'unit', headerName: '負責單位'},
                            {field: 'content', headerName: '項目', flex: 1},
                            {
                                field: 'startDate',
                                headerName: '開始日期',
                                valueFormatter: params => format(new Date(params?.value), "yyyy-MM-dd")
                            },
                            {
                                field: 'endDate',
                                headerName: '結束日期',
                                valueFormatter: params => format(new Date(params?.value), "yyyy-MM-dd")
                            },
                            {
                                field: 'assignee',
                                headerName: '負責人',
                                valueGetter: params => params?.value?.name,
                            },
                            {
                                field: 'notifyCustomer',
                                headerName: '通知客戶',
                                valueGetter: params => params?.value ? "O" : "X",
                            },
                            {
                                field: 'notifySales',
                                headerName: '通知業務',
                                valueGetter: params => params?.value ? "O" : "X",
                            },
                        ]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={async ()=>{
                            setOpen(false);
                        }}
                    >
                        取消
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={async ()=>{
                            const url = `/order-schedule/export?ids=${selectedRows.join(',')}`;
                            await saveFile(url, `${order?.name}-時程表.xls`);
                        }}
                    >
                        下載
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                下載時程表
            </Button>
        </div>
    )
}