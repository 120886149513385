import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {useCreateOrderItemMutation, useGetOrderItemTemplatesQuery} from '../generated/graphql';
import { Container, Box, Button } from '@mui/material';
import { DataGridLocale } from '../common/Utils';
import Page from '../component/Page';
import MutationPopupButton from '../component/MutationPopupButton';
import {CustomerTypeMap} from "../common/Constant";
import NotificationPopup from "../component/NotificationPopup";

const OrderItemPage = () => {
    const navigate = useNavigate();
    const { data, loading, error } = useGetOrderItemTemplatesQuery();
    const [filterModel, setFilterModel] = useState<any>({ items: [] });
    const [createOrderItem] = useCreateOrderItemMutation();

    const createOrderItemFn = async (formData, { resetForm }, setOpen) => {
        try {

            // 執行新增 OrderItem 的 GraphQL mutation
            await createOrderItem({
                variables: {
                    orderItem: {
                        name: formData.name,
                    }
                },
                refetchQueries: ["getOrder", "getOrderItemTemplates"], // 可選：重新載入相關資料
            });

            // 執行新增成功後的操作，例如顯示成功訊息、重新載入資料等
            NotificationPopup.success("新增成功");

            // 重置表單並關閉彈出視窗
            resetForm();
            setOpen(false);
        } catch (error) {
            // 處理新增失敗的情況，例如顯示錯誤訊息
            NotificationPopup.error(`發生問題：${error.message}`);
        }
    };
    const columns = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'name', headerName: '專案名稱', width: 100 },
        { field: 'mediaItems', headerName: '項目', flex: 1,
            valueGetter: (params)=>params.value.map((mediaItem)=>mediaItem.name).join(", "), },
        {
            field: 'detail',
            headerName: '查看',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/order-items/${params.id}`)}
                >
                    查看
                </Button>
            ),
        },
    ];


    return (
        <Page title="專案項目列表">
            <Container maxWidth={false} style={{ padding: '30px' }}>
                <MutationPopupButton
                    title="新增專案"
                    columns={[
                        { name: "name", label: "專案名稱"}
                    ]}
                    onSubmit={createOrderItemFn} // 替換為處理新增 OrderItem 的函式
                    submitTitle="新增"
                />
                <Box width="100%" style={{ marginTop: '30px', height: '100vh' }}>
                    <DataGridPro
                        filterModel={filterModel}
                        onFilterModelChange={(filterModel) => {
                            setFilterModel(filterModel);
                        }}
                        style={{ borderTopLeftRadius: 'unset' }}
                        localeText={DataGridLocale}
                        autoHeight={true}
                        loading={loading}
                        rows={data?.orderItemTemplates ?? []}
                        columns={columns}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default OrderItemPage;
