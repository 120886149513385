import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useGetInvoicesQuery } from '../generated/graphql';
import {Container, Box, Button} from '@mui/material';
import { DataGridLocale } from '../common/Utils';
import Page from '../component/Page';
import {CustomerSourceMap, InvoiceStatusMap} from "../common/Constant";

const InvoicePage = () => {
    const navigate = useNavigate();
    const { data, loading, error } = useGetInvoicesQuery();

    const [filterModel, setFilterModel] = useState<any>({ items: [] });
    const columns = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'status', headerName: '狀態', valueFormatter: params => InvoiceStatusMap[params.value]},
        { field: 'number', headerName: '發票號碼', width: 150 },
        { field: 'order', headerName: '客戶', width: 200, valueGetter: (params)=> params.value?.customer.name },
        { field: 'amount', headerName: '金額', width: 100 },
        {
            field: 'detail',
            headerName: '查看',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: any) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/invoices/${params.id}`)}
                >
                    查看
                </Button>
            ),
        },
    ];

    return (
        <Page title={'發票列表'}>
            <Container maxWidth={false} style={{ padding: '30px' }}>
                <Box width={'100%'} style={{ marginTop: '30px', height: '100vh' }}>
                    <DataGridPro
                        filterModel={filterModel}
                        onFilterModelChange={(filterModel) => {
                            setFilterModel(filterModel);
                        }}
                        style={{ borderTopLeftRadius: 'unset' }}
                        localeText={DataGridLocale}
                        autoHeight={true}
                        loading={loading}
                        rows={data?.invoices ?? []}
                        columns={columns}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default InvoicePage;
