import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
    useCreateUserMutation,
    useGetUsersQuery,
    useUpdateUserMutation,
    useRemoveUserMutation, Role,
} from '../generated/graphql';
import { Container, Box, Button } from '@mui/material';
import {DataGridLocale, HasRole} from '../common/Utils';
import Page from '../component/Page';
import MutationPopupButton from '../component/MutationPopupButton';
import {InputType} from "../common/FormRenderer";
import {omit} from "lodash";
import NotificationPopup from "../component/NotificationPopup";
import ConfirmButton from "../component/ConfirmButton";
import {RoleList, RoleMap} from "../common/Constant";

const UserPage = () => {
    const navigate = useNavigate();
    const { data, loading, error } = useGetUsersQuery();
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [removeUser] = useRemoveUserMutation();
    const [filterModel, setFilterModel] = useState<any>({items: []});

    const onCreate = useCallback(async (user, { resetForm }, setOpen) => {
        try {
            await createUser({
                variables: {
                    user: user,
                },
                refetchQueries: ['getUsers'],
            });
            NotificationPopup.success(`新增成功`);
        } catch (e) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
        } finally {
            resetForm();
            setOpen(false);
        }
    }, []);

    const onUpdate = useCallback(async (user, {resetForm}, setOpen) => {
        try {
            await updateUser({
                variables: {
                    id: user.id,
                    user: omit(user, "id"),
                },
                refetchQueries: ['getUsers'],
            });
            setOpen(false);
            NotificationPopup.success(`修改完成`);
        } catch (e) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
        }
    }, []);

    const onDelete = useCallback(async (id) => {
        try {
            await removeUser({
                variables: {
                    id,
                },
                refetchQueries: ['getUsers'],
            });
            NotificationPopup.success(`刪除成功`);
        } catch (e) {
            NotificationPopup.error(`發生問題：${e.message}`);
        }
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'name', headerName: '姓名' },
        { field: 'roles', headerName: '職稱', width: 200, valueGetter: (params) => params.value.map((role)=>RoleMap[role]) },
        {
            field: 'edit',
            headerName: '編輯',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => (
                <MutationPopupButton
                    title={'編輯'}
                    columns={[
                        {
                            name: "id",
                            label: "ID",
                            value: params?.row.id,
                            type: InputType.hidden,
                        },
                        {
                            name: 'email',
                            label: '登入Email',
                            value: params.row.email
                        },
                        {
                            name: 'roles',
                            label: '職稱',
                            value: params.row.roles,
                            type: InputType.select,
                            options: RoleList,
                            isMulti: true,
                        },
                        {
                            name: 'name',
                            label: '姓名',
                            value: params.row.name
                        },
                        {
                            name: 'phone',
                            label: '電話',
                            value: params.row.phone
                        },
                        {
                            name: 'mobile',
                            label: '手機',
                            value: params.row.mobile
                        },
                        {
                            name: 'contactEmail',
                            label: '聯絡Email',
                            value: params.row.contactEmail
                        }
                    ]}
                    onSubmit={onUpdate}
                    submitTitle={'編輯'}
                />
            ),
        },
        {
            field: 'delete',
            headerName: '刪除',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => (
                <ConfirmButton
                    onConfirm={() => onDelete(params.id)}
                >
                    刪除
                </ConfirmButton>
            ),
        },
    ];

    return (
        <Page title={'User List'}>
            <Container maxWidth={false} style={{ padding: '30px' }}>
                <HasRole roles={[Role.Admin]}>
                    <MutationPopupButton
                        title={'新增使用者'}
                        columns={[
                            {
                                name: 'email',
                                label: '登入Email',
                            },
                            {
                                name: 'roles',
                                label: '職稱',
                                value: [],
                                type: InputType.select,
                                options: RoleList,
                                isMulti: true,
                            },
                            {
                                name: 'name',
                                label: '姓名',
                            },
                            {
                                name: 'phone',
                                label: '電話',
                            },
                            {
                                name: 'mobile',
                                label: '手機',
                            },
                            {
                                name: 'contactEmail',
                                label: '聯絡Email',
                            }
                        ]}
                        onSubmit={onCreate}
                        submitTitle={'新增使用者'}
                    />
                </HasRole>


                <Box width={'100%'} style={{ marginTop: '30px', height: '100vh' }}>
                    <DataGridPro
                        filterModel={filterModel}
                        onFilterModelChange={(filterModel) => {
                            setFilterModel(filterModel);
                        }}
                        style={{ borderTopLeftRadius: 'unset' }}
                        localeText={DataGridLocale}
                        autoHeight={true}
                        loading={loading}
                        rows={data?.users ?? []}
                        columns={columns}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default UserPage;

