import {AppBar, Button, Grid, Tab, Tabs} from "@mui/material";
import React, {useCallback} from "react";
import Page from "../component/Page";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import TabPanel from "../common/TabPanel";
import {
    FileType,
    OrderStatus,
    ReviewStatus,
    Role,
    useCloneOrderItemTemplateMutation,
    useCreateOrderItemMutation,
    useGetOrderItemTemplatesQuery,
    useGetOrderQuery,
    useUpdateOrderMutation,
} from "../generated/graphql";
import DetailView from "../component/DetailView";
import {useParams} from "react-router";
import OrderSchedule from "./OrderSchedule";
import ProductItemPage from "../productItems/ProductItemPage";
import InvoiceInOrderPage from "../invoices/InvoiceInOrderPage";
import {NavLink} from "react-router-dom";
import UploadFile from "../component/UploadFile";
import {getNextStatus, OrderStatusList, OrderStatusMap} from "../common/Constant";
import UpdateOrderButton from "./UpdateOrderButton";
import {getErrorNotifyElement, hasRole, selectMapFn, useTabIndex} from "../common/Utils";
import {OrderItemDetail} from "../order-items/OrderItemDetail";
import {InputType} from "../common/FormRenderer";
import MutationPopupButton from "../component/MutationPopupButton";
import NotificationPopup from "../component/NotificationPopup";
import CostItemPage from "../cost-items/CostItemPage";
import ConfirmButton from "../component/ConfirmButton";
import ConditionalWrapper from "../common/ConditionalWrapper";
import CreateView from "../component/CreateView";
import {useUser} from "../auth/Auth";

export function compareStatus(firstStatus: string|undefined, secondStatus: string): boolean {
    const firstIndex = OrderStatusList.findIndex(item => item.value === firstStatus);
    const secondIndex = OrderStatusList.findIndex(item => item.value === secondStatus);

    if (firstIndex === -1 || secondIndex === -1) {
        // 如果有一个状态不在列表中，则返回 false 或抛出异常，具体取决于你的需求
        return false;
    }
    return firstIndex >= secondIndex;
}

const OrderDetail = () =>
{
    const {id: idString} = useParams();
    const id = idString ? parseInt(idString) : 0;
    const {loading, error, data} = useGetOrderQuery({
        variables: {id},
    });
    const [tabIndex, setTabIndex] = useTabIndex();
    const [updateOrder] = useUpdateOrderMutation();
    const [createOrderItem] = useCreateOrderItemMutation();
    const orderItemsTemplatesQuery = useGetOrderItemTemplatesQuery();
    const [cloneOrderItem] = useCloneOrderItemTemplateMutation();
    const user = useUser();
    const columns = [
        ...(data?.order.reviewMessage!=null ? [{field: "reviewMessage", headerName: "拒絕原因"}] : [])
        ,{
            headerName: "階段",
            field: "status",
            valueFormatter: (value) => `${OrderStatusMap[value]}${data?.order.reviewing ? "(簽核中)" : ""}` +
                (value == 'finished' ? (data?.order.invoices.find((invoice) => invoice.status != 'paid') ? "(尚未完成收款)" : "(已收款)") : ""),
        },
        {field: "number", headerName: "委刊單號"},
        {
            field: "customer",
            headerName: "客戶名稱",
            valueFormatter: (value) => value?.name,
        },
        {
            field: "brand",
            headerName: "品牌名稱",
            valueFormatter: (value) => value?.name,
        },
        {field: "name", headerName: "專案名稱"},
        {field: "createdAt", headerName: "建立時間"},
        {field: "startDate", headerName: "執行開始日期"},
        {field: "endDate", headerName: "執行結束日期"},
        {field: "signDate", headerName: "委刊單日期"},
        // {field: "totalAmount", headerName: "委刊金額", valueFormatter: (value) => value?.toLocaleString()},
        {field: "contact", headerName: "聯絡人"},
        {field: "files", headerName: "回簽檔"},
        {field: "customerRequirementsFiles", headerName: "客戶需求單"},
        {
            field: "assignee",
            headerName: "負責人",
            valueFormatter: (user) => (user ? user.name : "無"),
        },
        {
            field: "backup",
            headerName: "附註",
        }
    ];
    const updateOrderFn = async (order) =>
    {
        await updateOrder({
            variables: {
                id,
                order,
            },
            refetchQueries: ['getOrder', 'getOrders'],
        });
    }
    const onReject = useCallback(
        async (order, {resetForm}, setOpen) =>
        {
            try {
                await updateOrder({
                    variables: {
                        id,
                        order,
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success(`退回完成`);
            } catch (e) {
                NotificationPopup.error(`發生問題 ${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateOrder]
    );

    const createOrderItemFn = async (formData, {resetForm}, setOpen) =>
    {
        try {

            // 執行新增 OrderItem 的 GraphQL mutation
            await cloneOrderItem({
                variables: {
                    orderId: data!.order.id, // 使用從表單中取得的 orderId
                    templateId: formData.orderItemId,
                },
                refetchQueries: ["getOrder"], // 可選：重新載入相關資料
            });

            // 執行新增成功後的操作，例如顯示成功訊息、重新載入資料等
            NotificationPopup.success("新增成功");

            // 重置表單並關閉彈出視窗
            resetForm();
            setOpen(false);
        } catch (error) {
            // 處理新增失敗的情況，例如顯示錯誤訊息
            NotificationPopup.error(`發生問題：${error.message}`);
        }
    };
    const canCloseCase = () => {
        const existNullActualAmount = data?.order.costItems.find((costItem)=>costItem.actualAmount==null);
        const existNotPaidInvoice = data?.order.invoices.find((invoice)=>invoice.paidDate == null);
        return data?.order.status == OrderStatus.Running &&
            data?.order.reviewing == false &&
            !existNullActualAmount &&
            !existNotPaidInvoice &&
            data?.order.scheduleReviewStatus == ReviewStatus.Finished &&
            data?.order.closureReviewStatus == ReviewStatus.Finished
            ;
    };
    const canEditOrder = () => {
        return data?.order.status == OrderStatus.Draft && data?.order.reviewing == false;
    }
    const ReviewComponent = ({condition, approveFn, rejectComponent}) => {
        return <ConditionalWrapper condition={condition&&hasRole(user, [Role.Admin, Role.CorporateManager, Role.SalesManager])}>
            <Grid item>
                <ConfirmButton onConfirm={approveFn}>
                    簽核通過
                </ConfirmButton>
            </Grid>
            <Grid item>
                {rejectComponent}
            </Grid>
        </ConditionalWrapper>
    }

    return (
        <Page title={"訂單資料"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(event, newValue) =>
                    {
                        setTabIndex(newValue);
                    }}
                >
                    <Tab label="委刊單內容"/>
                    <Tab label={(data?.order.orderItems.length == 0 && data?.order.productItems.length == 0) ? getErrorNotifyElement("專案項目") : "專案項目"}/>
                    <Tab label={(data?.order.invoices.length == 0) ? getErrorNotifyElement("付款項目") : "付款項目"}/>
                    { compareStatus(data?.order.status,OrderStatus.Running) &&
                        <Tab label={(data?.order.orderSchedules.length == 0) ? getErrorNotifyElement("時程表") : "時程表"}></Tab>
                    }
                    { compareStatus(data?.order.status,OrderStatus.Running) && <Tab label="結案"></Tab>}
                </Tabs>
            </AppBar>
            <DefaultQueryHandler error={error} loading={loading}>
                <TabPanel value={tabIndex} index={0}>

                        <Grid container spacing={2} alignItems={"center"}>
                            <ReviewComponent
                                condition={data?.order.reviewing == true}
                                approveFn={async ()=>{
                                    await updateOrderFn({status: getNextStatus(data!.order.status), reviewMessage: null, reviewing: false});
                                }}
                                rejectComponent={
                                    <CreateView
                                        title={"拒絕簽核"}
                                        columns={[
                                            {
                                                name: "reviewMessage",
                                                label: "拒絕原因",
                                            },
                                            {
                                                name: "status",
                                                label: "退回階段",
                                                value: data?.order.status,
                                                options: OrderStatusList,
                                                type: InputType.select,
                                                required: true,
                                            },
                                            {
                                                name: "reviewing",
                                                value: false,
                                                type: InputType.hidden,
                                            }
                                        ]}
                                        onSubmit={onReject}
                                    />
                                }
                            />
                        </Grid>
                        <br/>
                        <Grid container spacing={2} alignItems={"center"}>
                            <ConditionalWrapper condition={canEditOrder()}>
                                <Grid item>
                                    <UpdateOrderButton order={data?.order}/>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton onConfirm={async ()=>{
                                        await updateOrderFn({reviewing: true});
                                    }}>
                                        委刊單送審
                                    </ConfirmButton>
                                </Grid>
                            </ConditionalWrapper>
                            <ConditionalWrapper condition={
                                ((data?.order.status == OrderStatus.Draft) && (data?.order.reviewing == true)) ||
                                ((data?.order.status == OrderStatus.Signed) && (data?.order.reviewing == false))
                            }>
                                <Grid item>
                                    <Button variant={"contained"} color={"primary"} component={NavLink} target="_blank"
                                            to={`${process.env.REACT_APP_BACKEND_URI}/order/contract/${data?.order.id}`}>
                                        下載委刊單
                                    </Button>
                                </Grid>
                            </ConditionalWrapper>
                            <ConditionalWrapper condition={
                                (data?.order.status == OrderStatus.Signed) && (data?.order.reviewing == false)
                            }>
                                <Grid item>
                                    <UploadFile
                                        uriPart={`/order/upload/${id}?fileType=${FileType.Signature}`}
                                        uploadTitle={"上傳回簽檔"}
                                        onSuccess={async () =>
                                        {
                                            await updateOrderFn({reviewing: true});
                                        }}
                                    />
                                </Grid>
                            </ConditionalWrapper>

                            <ConditionalWrapper condition={compareStatus(data?.order.status,OrderStatus.CustomerRequirements) && data?.order.reviewing == false}>
                                <Grid item>
                                    <UploadFile
                                        uriPart={`/order/upload/${id}?fileType=${FileType.CustomerRequirements}`}
                                        uploadTitle={"上傳客戶需求單"}
                                        onSuccess={async () =>
                                        {
                                            await updateOrderFn({reviewing: true});
                                        }}
                                    />
                                </Grid>
                            </ConditionalWrapper>
                            <ConditionalWrapper
                                condition={canCloseCase()}>
                                <Grid item>
                                    <ConfirmButton
                                        onConfirm={async () =>
                                        {
                                            await updateOrderFn({status: 'finished'});
                                        }}
                                    >
                                        結案
                                    </ConfirmButton>
                                </Grid>
                            </ConditionalWrapper>
                        </Grid>
                    <DetailView data={data?.order} columns={columns}/>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <ConditionalWrapper condition={canEditOrder()}>
                        <MutationPopupButton
                            title="匯入專案"
                            columns={[
                                {
                                    name: "orderItemId",
                                    label: "專案名稱",
                                    type: InputType.select,
                                    options: orderItemsTemplatesQuery.data?.orderItemTemplates.map(selectMapFn),
                                }
                            ]}
                            onSubmit={createOrderItemFn} // 替換為處理新增 OrderItem 的函式
                            submitTitle="新增"
                        />
                    </ConditionalWrapper>
                    {data?.order.orderItems?.map((orderItem) =>
                        <OrderItemDetail myOrderItem={orderItem} canEdit={canEditOrder()}></OrderItemDetail>)}
                    <br/>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item>
                            <MutationPopupButton
                                title={"修改委刊金額"}
                                columns={[
                                    {
                                        name: "id",
                                        label: "ID",
                                        value: data?.order.id,
                                        type: InputType.hidden,
                                    },
                                    {
                                        name: "totalAmount",
                                        label: "委刊金額",
                                        value: data?.order.totalAmount,
                                        type: InputType.number,
                                    },
                                ]}
                                onSubmit={async (updatedOrder, { resetForm }, setOpen)=> {
                                    setOpen(false);
                                    await updateOrderFn({totalAmount: updatedOrder.totalAmount});
                                }}
                                submitTitle={"修改委刊金額"}
                            />
                        </Grid>
                        <Grid item>
                            委刊金額：{data?.order.totalAmount?.toLocaleString()}
                        </Grid>
                    </Grid>

                    <ProductItemPage order={data?.order} canEdit={canEditOrder()}></ProductItemPage>
                    <br/>
                    <CostItemPage order={data?.order} canEdit={canEditOrder()}/>
                </TabPanel>

                <TabPanel value={tabIndex} index={2}>
                    <InvoiceInOrderPage order={data?.order} canEdit={canEditOrder()}/>
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>

                    <Grid container spacing={2} alignItems={"center"}>
                        <ReviewComponent
                            condition={data?.order.scheduleReviewStatus == ReviewStatus.Reviewing}
                            approveFn={async ()=>{
                                await updateOrderFn({scheduleReviewStatus: ReviewStatus.Finished});
                            }}
                            rejectComponent={
                                <ConfirmButton onConfirm={async () => {
                                    await updateOrderFn({scheduleReviewStatus: ReviewStatus.Started});
                                }}>
                                    拒絕簽核
                                </ConfirmButton>
                            }
                        ></ReviewComponent>
                    </Grid>
                    <br/>
                    <OrderSchedule order={data?.order}/>
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid container spacing={2} alignItems={"center"}>
                            <ReviewComponent
                                condition={data?.order.closureReviewStatus == ReviewStatus.Reviewing}
                                approveFn={async ()=>{
                                    await updateOrderFn({closureReviewStatus: ReviewStatus.Finished});
                                }}
                                rejectComponent={
                                    <ConfirmButton onConfirm={async () => {
                                        await updateOrderFn({closureReviewStatus: ReviewStatus.Started});
                                    }}>
                                        拒絕簽核
                                    </ConfirmButton>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <ConfirmButton onConfirm={async ()=>{
                                await updateOrderFn({closureReviewStatus: ReviewStatus.Reviewing});
                            }}
                                           disabled={data?.order.closureReviewStatus != ReviewStatus.Started}
                                           disableTitle={data?.order.closureReviewStatus == ReviewStatus.Reviewing ? '審核中' : '已審核'}
                            >
                                送審
                            </ConfirmButton>
                        </Grid>
                        <ConditionalWrapper condition={data?.order.closureReviewStatus == ReviewStatus.Started}>
                            <Grid item>
                                <UploadFile
                                    uriPart={`/order/upload/${id}?fileType=${FileType.MeetingRecord}`}
                                    uploadTitle={"上傳會議紀錄"}
                                    onSuccess={async () =>
                                    {
                                        await updateOrderFn({status: data?.order.status});
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <UploadFile
                                    uriPart={`/order/upload/${id}?fileType=${FileType.ClosureReport}`}
                                    uploadTitle={"上傳結案報告"}
                                    onSuccess={async () =>
                                    {
                                        await updateOrderFn({status: data?.order.status});
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <UploadFile
                                    uriPart={`/order/upload/${id}?fileType=${FileType.Proposal}`}
                                    uploadTitle={"上傳提案資料"}
                                    onSuccess={async () =>
                                    {
                                        await updateOrderFn({status: data?.order.status});
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <UploadFile
                                    uriPart={`/order/upload/${id}?fileType=${FileType.GreatVideo}`}
                                    uploadTitle={"上傳優良影片"}
                                    onSuccess={async () =>
                                    {
                                        await updateOrderFn({status: data?.order.status});
                                    }}
                                />
                            </Grid>
                        </ConditionalWrapper>
                    </Grid>
                    <DetailView data={data?.order} columns={[
                        {field: "meetingFiles", headerName: "會議紀錄"},
                        {field: "closureFiles", headerName: "結案報告"},
                        {field: "proposalFiles", headerName: "提案資料"},
                        {field: "greatVideoFiles", headerName: "優良影片(需上傳3支)"},
                    ]}/>
                </TabPanel>
            </DefaultQueryHandler>
        </Page>
    );
};

export default OrderDetail;
