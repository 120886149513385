import React, { useCallback } from "react";
import { useParams } from "react-router";
import { useGetInterviewQuery, useUpdateInterviewMutation, useRemoveInterviewMutation } from "../generated/graphql";
import EditView, { getModifiedValues } from "../component/EditView";
import NotificationPopup from "../component/NotificationPopup";
import { AppBar, Tab, Tabs } from "@mui/material";
import Page from "../component/Page";
import { useTabIndex } from "../common/Utils";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import TabPanel from "../common/TabPanel";
import {InputType} from "../common/FormRenderer";
import {BudgetLevelList, CooperationChanceList, NextStepList} from "../common/Constant";

const InterviewDetail = () => {
    const { id: idString } = useParams();
    const id = idString ? parseInt(idString) : 0;

    const [tabIndex, setTabIndex] = useTabIndex();
    const { loading, error, data } = useGetInterviewQuery({
        variables: { id },
    });

    const [updateInterview] = useUpdateInterviewMutation();
    const [removeInterview] = useRemoveInterviewMutation();

    const onUpdate = useCallback(
        async (values, { resetForm }) => {
            try {
                const modifiedValues = getModifiedValues(values, data?.interview, "修改");
                await updateInterview({
                    variables: {
                        id,
                        interview: modifiedValues,
                    },
                    refetchQueries: ["getInterview", "getInterviews"],
                });
                NotificationPopup.success("修改成功");
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
                console.error(e);
            }
        },
        [updateInterview, data, id]
    );

    const onRemove = useCallback(async () => {
        try {
            await removeInterview({
                variables: {
                    id,
                },
            });
            NotificationPopup.success("刪除成功");
        } catch (e) {
            NotificationPopup.error(`發生問題：${e.message}`);
            console.error(e);
        }
    }, [removeInterview, id]);

    const columns = [
        {
            name: 'productName',
            label: '產品名稱',
            type: InputType.text,
            value: data?.interview?.productName,
        },
        {
            name: 'productFeatures',
            label: '產品主要特色',
            type: InputType.textarea,
            value: data?.interview?.productFeatures,
        },
        {
            name: 'salesChannel',
            label: '產品銷售通路',
            type: InputType.textarea,
            value: data?.interview?.salesChannel,
        },
        {
            name: 'competitor',
            label: '產品競品',
            type: InputType.textarea,
            value: data?.interview?.competitor,
        },
        {
            name: 'targetAudience',
            label: '目標客群(TA)',
            type: InputType.textarea,
            value: data?.interview?.targetAudience,
        },
        {
            name: 'influencerRecords',
            label: '監播紀錄(有口碑文才要填)',
            type: InputType.textarea,
            value: data?.interview?.influencerRecords,
        },
        {
            name: 'pastCooperations',
            label: '過往有無合作過類似內容及規格',
            type: InputType.textarea,
            value: data?.interview?.pastCooperations,
        },
        {
            name: 'cooperationAndIssues',
            label: '與哪家合作 有遇到什麼問題',
            type: InputType.textarea,
            value: data?.interview?.cooperationAndIssues,
        },
        {
            name: 'demandReason',
            label: '為什麼會有這個需求',
            type: InputType.textarea,
            value: data?.interview?.demandReason,
        },
        {
            name: 'measurementCriteria',
            label: '該如何衡量這個需求被滿足',
            type: InputType.textarea,
            value: data?.interview?.measurementCriteria,
        },
        {
            name: 'interviewStrategy',
            label: '訪談策略',
            type: InputType.textarea,
            value: data?.interview?.interviewStrategy,
        },
        {
            name: 'customerFeedback',
            label: '客戶回饋',
            type: InputType.textarea,
            value: data?.interview?.customerFeedback,
        },
        {
            name: 'nextSteps',
            label: '下一步策略',
            type: InputType.select,
            options: NextStepList,
            isMulti: true,
            value: data?.interview?.nextSteps,
        },
        {
            name: 'nextStepOther',
            label: '其他下一步策略',
            type: InputType.textarea,
            value: data?.interview?.nextStepOther,
        },
        {
            name: 'budgetLevel',
            label: '預估預算等級',
            type: InputType.select,
            options: BudgetLevelList,
            value: data?.interview?.budgetLevel,
        },
        {
            name: 'cooperationChance',
            label: '合作機率預估',
            type: InputType.select,
            options: CooperationChanceList,
            value: data?.interview?.cooperationChance,
        },
    ];

    return (
        <Page title="訪談紀錄表">

            <DefaultQueryHandler error={error} loading={loading}>
                {/*<TabPanel value={tabIndex} index={0}>*/}
                    <EditView
                        columns={columns}
                        onSubmit={onUpdate}
                        onRemove={onRemove}
                    />
                {/*</TabPanel>*/}
            </DefaultQueryHandler>
        </Page>
    );
};

export default InterviewDetail;


