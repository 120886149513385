import {Box, Avatar, Divider, Drawer, Hidden, Typography, Select, MenuItem, Button, Grid} from "@mui/material";
import {makeStyles} from '@mui/styles';
import React, {useCallback, useContext, useEffect} from "react";
import {Link, NavLink as RouterLink, useLocation} from "react-router-dom";
// import {logout, useCompany, useUser} from "../auth/Auth";
// import {
//     BookOpen,
//     Briefcase, Home, Paperclip,
// } from "react-feather";
import MyMenuItem from "./MyMenuItem";
// import {Authorization, RoleEnum} from "../generated/graphql";
// import {hasAuthorization, hasRole} from "../common/Utils";
// import Logo from "../image/logo.png";
// import UserIcon from "../image/user.svg";
import CompanyIcon from "../image/company.svg";
import LaborContractIcon from "../image/labor_contract.svg";
import ReportIcon from "../image/report.svg";
import ContractorIcon from "../image/contractor.svg";
import {find, omit} from "lodash";
// import {CompanyContext} from "../routes";
import {format} from "date-fns";
// import {AuthorizationMap} from "../common/Constant";
import theme from "../theme/Theme";
import {Archive, Home, User} from "react-feather";
import {
    AiOutlineUser,
    FaAd, FaFileContract,
    FaReceipt,
    MdAccountCircle,
    MdPeople,
    RiFileEditLine,
    RiFileTextFill
} from "react-icons/all";
import {logout, useUser} from "../auth/Auth";
import {Role, useUpdateUserMutation} from "../generated/graphql";
import NotificationPopup from "../component/NotificationPopup";
import {InputType} from "../common/FormRenderer";
import {RoleList} from "../common/Constant";
import MutationPopupButton from "../component/MutationPopupButton";
import ConfirmButton from "../component/ConfirmButton";
// import ConfirmButton from "../component/ConfirmButton";

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 200,
    },
    desktopDrawer: {
        width: 200,
        height: "calc(100%)",
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
}));
const items = [
    {
        href: "/users",
        icon: MdAccountCircle,
        title: "使用者",
        roles: [Role.Admin],
    },
    {
        href: "/customers",
        icon: AiOutlineUser,
        title: "客戶",
    },
    {
        href: "/orders",
        icon: RiFileEditLine,
        title: "委刊單",
    },
    {
        href: "/order-items",
        icon: FaAd,
        title: "專案項目",
        roles: [Role.Admin],
    },
    {
        href: "/terms",
        icon: FaFileContract,
        title: "條款",
        roles: [Role.Admin],
    },
    {
        href: "/invoices",
        icon: FaReceipt,
        title: "發票",
        roles: [Role.Finance],
    },
    {
        href: "/interviews",
        icon: RiFileTextFill,
        title: "業務訪談表",
    },
]

const MenuBar = ({onMobileClose, openMobile}: any) =>
{
    const classes = useStyles();
    const location = useLocation();
    const user = useUser();
    const [updateUser] = useUpdateUserMutation();
    const onUpdate = useCallback(async (user, {resetForm}, setOpen) =>
    {
        try {
            await updateUser({
                variables: {
                    id: user.id,
                    user: omit(user, "id"),
                },
                refetchQueries: ['getUsers', 'getMe'],
            });
            setOpen(false);
            NotificationPopup.success(`修改完成`);
        } catch (e) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
        }
    }, []);
    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <MutationPopupButton
                    title={<Grid alignItems="center"
                                display="flex"
                                flexDirection="column"><Avatar
                        className={classes.avatar}
                        src={user?.picture as string}
                    />
                        <Typography
                            color="textPrimary"
                            variant="h5"
                            style={{marginBottom: 10, marginTop: 10}}
                        >
                            {user?.name}
                        </Typography>
                    </Grid>}
                    columns={[
                        {
                            name: "id",
                            label: "ID",
                            value: user?.id,
                            type: InputType.hidden,
                        },
                        {
                            name: 'email',
                            label: '登入Email',
                            value: user?.email,
                            disabled: true,
                        },
                        {
                            name: 'roles',
                            label: '職稱',
                            value: user?.roles,
                            type: InputType.select,
                            options: RoleList,
                            isMulti: true,
                            disabled: true,
                        },
                        {
                            name: 'name',
                            label: '姓名',
                            value: user?.name
                        },
                        {
                            name: 'phone',
                            label: '電話',
                            value: user?.phone
                        },
                        {
                            name: 'mobile',
                            label: '手機',
                            value: user?.mobile
                        },
                        {
                            name: 'contactEmail',
                            label: '聯絡Email',
                            value: user?.contactEmail
                        }
                    ]}
                    onSubmit={onUpdate}
                    submitTitle={'編輯'}
                />
            </Box>
            <Divider style={{borderColor: "#DDD"}}/>
            <Box
                p={2}
                alignItems="center"
                display="flex"
                flexDirection="column"
            >
                {items.map((item) =>
                {
                    if (item.roles==null || item.roles.filter(role => user?.roles?.includes(role)).length != 0) {
                        return <MyMenuItem
                            style={{width: 150}}
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            selected={location.pathname.includes(item.href)}
                        />
                    }
                    else {
                        return ;
                    }

                })}
                <ConfirmButton
                    onConfirm={() => {
                        logout();
                        window.location.reload();
                    }}>
                    登出
                </ConfirmButton>
                {/*<img src={Logo} style={{width: 66, height: 66, marginTop: 20}}/>*/}
            </Box>
        </Box>
    );
    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                    PaperProps={{style: {borderColor: "#DDD"}}}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

export default MenuBar;