import {OrderStatus} from "../generated/graphql";

export const listToMap = (list) => {
    let result = {}
    list.map((item) => {
        result[item.value] = item.label
    })
    return result
}
export const OrderStatusList = [
    { value: 'draft', label: '草稿' },
    { value: 'signed', label: '上傳回簽' },
    { value: 'customerRequirements', label: '上傳客戶需求' },
    { value: 'running', label: '執行中' },
    { value: 'finished', label: '已結案' },
    { value: 'expired', label: '已作廢' },
]
export const OrderStatusMap = listToMap(OrderStatusList);

export const getNextStatus = (currentStatus) => {
    if (currentStatus==OrderStatus.Running) {
        return OrderStatus.Running;
    }
    const currentIndex = OrderStatusList.findIndex(status => status.value === currentStatus);
    if (currentIndex !== -1 && currentIndex < OrderStatusList.length - 1) {
        return OrderStatusList[currentIndex + 1].value;
    }
    return OrderStatus.Finished;
}

export const CustomerSourceList = [
    { value: 'assigned', label: '公司導入' },
    { value: 'inbound', label: '業務自帶' },
    { value: 'cold_calling', label: '陌生開發' },
    { value: 'ama', label: 'AMA' },
]
export const CustomerSourceMap = listToMap(CustomerSourceList);

export const CustomerTypeList = [
    { value: 'agent', label: '廣告客戶(代理商)' },
    { value: 'direct', label: '廣告客戶(直客)' },
    { value: 'director', label: '董監事' },
    { value: 'ec', label: 'EC客戶' },
    { value: 'eshop', label: '電商通路' },
    { value: 'guild', label: '公關客戶' },
    { value: 'kol', label: '網紅' },
    { value: 'management', label: '管理部合作單位' },
    { value: 'media', label: '媒體同業' },
    { value: 'other', label: '其他' },
    { value: 'public', label: '公協會' },
    { value: 'sales', label: '業務同業' },
    { value: 'stockholder', label: '股東' },
]

export const CustomerTypeMap = listToMap(CustomerTypeList);

export const RoleList = [
    { value: 'admin', label: '系統管理者' },
    { value: 'salesManager', label: '業務主管' },
    { value: 'corporateManager', label: '業企主管' },
    { value: 'finance', label: '財務' },
    { value: 'sales', label: '業務' },
    { value: 'corporate', label: '業企' },
]
export const RoleMap = listToMap(RoleList);

export const InvoiceStatusList = [
    { value: 'pending', label: '等待開立' },
    { value: 'expired', label: '已作廢' },
    { value: 'paid', label: '已付款' },
    { value: 'posted', label: '已開立' },
]
export const InvoiceStatusMap = listToMap(InvoiceStatusList);

export const NextStepList = [
    { value: 'SEND_INFO', label: '寄資料' },
    { value: 'SEND_PROPOSAL', label: '寄提案' },
    { value: 'PHONE_CALL', label: '通電話' },
    { value: 'VISIT', label: '拜訪' },
    { value: 'OTHER', label: '其他' },
];
export const NextStepMap = listToMap(NextStepList);

export const BudgetLevelList = [
    { value: 'LOW', label: '0-10萬' },
    { value: 'MEDIUM', label: '10萬-20萬' },
    { value: 'HIGH', label: '20萬以上' },
];
export const BudgetLevelMap = listToMap(BudgetLevelList);

export const CooperationChanceList = [
    { value: 'LOW', label: '低' },
    { value: 'MEDIUM', label: '中' },
    { value: 'HIGH', label: '高' },
];
export const CooperationChanceMap = listToMap(CooperationChanceList);

export const IndustryTypeList = [
    { value: 'AGENT', label: '代理商' },
    { value: 'PARENT_CHILD', label: '親子' },
    { value: 'ELECTRONICS', label: '3C家電' },
    { value: 'FOOD', label: '食品' },
    { value: 'HAIR', label: '美髮' },
    { value: 'BEAUTY', label: '美妝' },
    { value: 'HEALTH', label: '保健' },
    { value: 'CLOTHING', label: '服飾' },
    { value: 'DAILY_USE', label: '日用品' },
    { value: 'RETAIL', label: '通路' },
    { value: 'FOOD_BEVERAGE', label: '餐飲' },
    { value: 'GOVERNMENT', label: '公家機關' },
    { value: 'OTHER', label: '其他' },
];
export const IndustryTypeMap = listToMap(IndustryTypeList);

export const OrderScheduleItemList = [
    { value: '啟動會議', label: '啟動會議' },
    { value: '提供拍攝大綱、人選', label: '提供拍攝大綱、人選' },
    { value: '定調會議（人選與拍攝大綱會議）', label: '定調會議（人選與拍攝大綱會議）' },
    { value: 'C Girls 拍攝人選、大綱 提供-說明會議(concall)', label: 'C Girls 拍攝人選、大綱 提供-說明會議(concall)' },
    { value: '拍攝人選確認符合輪廓（不可挑人，但若有疑慮可提出討論）', label: '拍攝人選確認符合輪廓（不可挑人，但若有疑慮可提出討論）' },
    { value: '拍攝大綱確認', label: '拍攝大綱確認' },
    { value: '產品寄出C Girls', label: '產品寄出C Girls' },
    { value: 'C Girls 佈達拍攝大綱、時程、注意事項', label: 'C Girls 佈達拍攝大綱、時程、注意事項' },
    { value: 'C Girls交稿', label: 'C Girls交稿' },
    { value: '內部QC', label: '內部QC' },
    { value: '反饋給 C Girls', label: '反饋給 C Girls' },
    { value: '修改交稿', label: '修改交稿' },
    { value: '提供Cgirls圖、影給客戶(品牌不得修改內容僅修正錯別字)', label: '提供Cgirls圖、影給客戶(品牌不得修改內容僅修正錯別字)' },
    { value: '客戶確認Cgirls圖、影', label: '客戶確認Cgirls圖、影' },
    { value: '反饋給 C Girls', label: '反饋給 C Girls' },
    { value: '修改交稿', label: '修改交稿' },
    { value: '提供v2版C girls圖、影給客戶(品牌不得修改內容僅修正錯別字)', label: '提供v2版C girls圖、影給客戶(品牌不得修改內容僅修正錯別字)' },
    { value: '客戶final確認Cgirls圖、影', label: '客戶final確認Cgirls圖、影' },
    { value: '影音、圖文C girls 上線自媒體', label: '影音、圖文C girls 上線自媒體' },
    { value: '提供精選影音給客戶(品牌不得修改內容僅修正錯別字)', label: '提供精選影音給客戶(品牌不得修改內容僅修正錯別字)' },
    { value: '客戶確認精選影音', label: '客戶確認精選影音' },
    { value: '精選影音上線官方', label: '精選影音上線官方' },
    { value: '廣告投放30,000', label: '廣告投放30,000' },
    { value: '製作結案報告', label: '製作結案報告' },
    { value: '結案報告完成（廣告、截圖、留言）', label: '結案報告完成（廣告、截圖、留言）' },
    { value: '結案報告提供', label: '結案報告提供' },
    { value: '支出、成本、業務表', label: '支出、成本、業務表' }
];
