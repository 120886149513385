import {
    TextField,
    Card,
    Container,
    Grid,
    Box,
    Button,
} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import ContactView from "../component/ContactView";
import {previewPdf, saveFile} from "../common/Utils";
import {format} from "date-fns";
import ConfirmButton from "../component/ConfirmButton";
import NotificationPopup from "../component/NotificationPopup";
import {useRemoveFileMutation} from "../generated/graphql";

export default ({data, columns}) =>
{
    const [removeFile] = useRemoveFileMutation({refetchQueries: ["getOrder", "getCustomer"]});
    /*
    const columns = [
        { field: "id", headerName: "id", width: 80 },
        { field: "name", headerName: "供應商名稱", width: 150 },
        { field: "address", headerName: "地址", width: 300 },
        { field: "invoiceTitle", headerName: "發票抬頭", width: 150 },
        { field: "taxId", headerName: "統一編號", width: 150 },
    ];
    */
    return (
        <div>
            {/* <Grid container> */}
            {data != null &&
            columns.map((column) =>
            {
                let title = column.headerName;
                let field = column.field;
                let valueFormatter = column.valueFormatter;
                var value = data[field];
                if (valueFormatter) {
                    value = valueFormatter(data[field]);
                }
                return (
                    <Grid
                        key={title + field}
                        container
                        style={{
                            borderBottom: "1px solid #dce0e0",
                            paddingTop: 15,
                            paddingBottom: 15,
                            color: column.color ? column.color : "black",
                        }}
                        alignItems={"center"}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                                paddingLeft: 30,
                                color: "#666",
                            }}
                        >
                            {title}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {field == "customer" ? (
                                <Link
                                    to={`/customers/${data[field].id}`}
                                >
                                    {value}
                                </Link>
                            ) : field == "contact" ||
                            field == "invoiceContact" ? (
                                <ContactView contact={data[field]}/>
                            ) : field == "createdAt" || field == 'updatedAt' ? (
                                format(
                                    new Date(data[field]),
                                    "yyyy-MM-dd HH:mm"
                                )
                            ) : (data[field] != null && field.toLowerCase().includes("date")) ? (
                                format(
                                    new Date(data[field]),
                                    "yyyy-MM-dd"
                                )
                            ) : field == "order" ? (
                                <Link
                                    to={`/${field}s/${data[field].id}?index=2`}
                                >
                                    {valueFormatter ? value : data[field].number}
                                </Link>
                            ) : field.toLowerCase().includes("files") ? (
                                data[field].map((file, index) =>
                                {
                                    return (
                                        <Grid container spacing={1} alignItems={"center"}>
                                            <Grid item>
                                                <a
                                                    href={"#"}
                                                    onClick={() =>
                                                        saveFile(
                                                            `/files/${file.id}`,
                                                            file.name
                                                        )
                                                    }
                                                    style={{marginRight: 10}}
                                                >
                                                    {file.name}
                                                </a>
                                            </Grid>
                                            <Grid item>
                                                {file.name.endsWith('.pdf') && <Button
                                                    variant={"contained"}
                                                    color={"secondary"}
                                                    onClick={() =>
                                                    {
                                                        previewPdf(
                                                            `/files/${file.id}`,
                                                            file.name
                                                        );
                                                    }}
                                                >
                                                    預覽
                                                </Button>}
                                            </Grid>
                                            <Grid item>
                                                <ConfirmButton onConfirm={async () =>
                                                {
                                                    try {
                                                        await removeFile({variables: {id: file.id}})
                                                        NotificationPopup.success(`刪除成功`);
                                                    } catch (e) {
                                                        console.error(e);
                                                        NotificationPopup.success(`刪除檔案發生問題: ${e.message}`);
                                                    }
                                                }}>
                                                    刪除
                                                </ConfirmButton>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            ) : (
                                value
                            )}
                        </Grid>
                    </Grid>
                );
            })}

            {/* </Grid> */}
        </div>
    );
};
