import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    Typography,
} from "@mui/material";
import FormRenderer from "../common/FormRenderer";
import {Formik} from "formik";
import React, {useState} from "react";
import {reduce} from "lodash";
import theme from "../theme/Theme";
import ConfirmButton from "./ConfirmButton";

export default ({title, columns, onSubmit, onRemove=null, submitTitle = '新增', description=null}:
{ title, columns, onSubmit, onRemove?: (()=>void) | null, submitTitle: string, description?: string | null}) => {
    const [open, setOpen] = useState(false);
    const isComponentTitle = typeof title !== "string";
    return (
        <div>
            <Dialog open={open} fullWidth={true}>
                {!isComponentTitle && <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                    <Typography variant="h4" align="center">
                        {title}
                    </Typography>

                </DialogTitle>}

                <Typography variant="h5" align="center" style={{color: "red"}}>
                    {description}
                </Typography>
                <Formik
                    initialValues={reduce(
                        columns,
                        (acc, column) => {
                            return {...acc, [column.name]: column.value};
                        },
                        {}
                    )}
                    onSubmit={(values, bla) => onSubmit(values, bla, setOpen)}
                >
                    {({handleChange, resetForm, handleSubmit, values}) => {
                        columns.map((column) => {
                            if ('onChange' in column) {
                                column.onChange(values[column.name])
                            }
                        })
                        return (
                            <form onSubmit={handleSubmit}>
                                <DialogContent>
                                    <FormRenderer
                                        values={values}
                                        def={columns}
                                        onChange={handleChange}
                                    />
                                </DialogContent>
                                <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            {onRemove && <ConfirmButton
                                                onConfirm={onRemove}
                                            >
                                                刪除
                                            </ConfirmButton>}
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant={"contained"}
                                                color={"primary"}
                                                type={"submit"}
                                            >
                                                {submitTitle}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                variant={"contained"}
                                                color={"secondary"}
                                                onClick={() => {
                                                    setOpen(false);
                                                    resetForm();
                                                }}
                                            >
                                                取消
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
            {!isComponentTitle && (
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    {title}
                </Button>
            )}
            {isComponentTitle && <div onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
                {title}
            </div>}
        </div>
    );
};
