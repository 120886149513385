import React, {useCallback, useState} from 'react';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {InvoiceStatus, useCreateInvoiceMutation, useUpdateInvoiceMutation} from '../generated/graphql';
import {Box, Button, Container} from "@mui/material";
import {DataGridLocale, dateValueFormatter} from "../common/Utils";
import Page from "../component/Page";
import MutationPopupButton from "../component/MutationPopupButton";
import {InputType} from "../common/FormRenderer";
import {omit} from "lodash";
import {InvoiceStatusList, InvoiceStatusMap} from "../common/Constant";
import {useNavigate} from "react-router-dom";
import NotificationPopup from "../component/NotificationPopup";

const InvoiceInOrderPage = ({order, canEdit=true}) =>
{
    const navigate = useNavigate();
    const [createInvoice] = useCreateInvoiceMutation();
    const [updateInvoice] = useUpdateInvoiceMutation();
    const onCreate = useCallback(
        async (invoice, {resetForm}, setOpen) =>
        {
            try {
                await createInvoice({
                    variables: {
                        invoice: {...invoice, orderId: order.id},
                    },
                    refetchQueries: ["getOrder"],
                });
                NotificationPopup.success(`新增成功`);
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createInvoice]
    );
    const onUpdate = useCallback(
        async (invoice, {resetForm}, setOpen) =>
        {
            try {
                await updateInvoice({
                    variables: {
                        id: invoice.id,
                        invoice: omit(invoice, "id"),
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success(`修改完成`);
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateInvoice]
    );
    const [filterModel, setFilterModel] = useState<any>({items: []});
    const columns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: 'title', headerName: '發票抬頭'},
        {field: 'status', headerName: '狀態', valueFormatter: params => InvoiceStatusMap[params.value]},
        {field: 'expectedPostDate', headerName: '預期開立日期', valueFormatter: dateValueFormatter},
        {field: 'postDate', headerName: '實際開立日期', valueFormatter: dateValueFormatter},
        {field: 'expectedPaidDate', headerName: '預期付款日期', valueFormatter: dateValueFormatter},
        {field: 'paidDate', headerName: '實際付款日期', valueFormatter: dateValueFormatter},
        {field: 'number', headerName: '發票號碼'},
        {field: 'amount', headerName: '金額', valueFormatter: params => params.value?.toLocaleString()},
        {field: 'note', headerName: '備註'},
        {
            field: 'edit',
            headerName: '查看',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: any) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/invoices/${params.id}`)}
                >
                    查看
                </Button>
            ),
        },
    ];

    return (
        <>
            {canEdit && <MutationPopupButton
                title={"新增付款項目"}
                columns={[
                    {
                        name: 'expectedPostDate',
                        label: '預期開立日期',
                        type: InputType.date,
                        updateForm: (form) => {
                            form.setFieldValue('amount', 3000);
                        },
                    },
                    {
                        name: 'expectedPaidDate',
                        label: '預期付款日期',
                        type: InputType.date,
                    },
                    {
                        name: 'title',
                        label: '發票抬頭',
                        value: order.name,
                    },
                    {
                        name: 'amount',
                        label: '金額',
                        type: InputType.number,
                    },
                    {
                        name: 'note',
                        label: '備註',
                    },
                    {
                        name: 'status',
                        label: '狀態',
                        value: InvoiceStatus.Pending,
                        type: InputType.hidden,
                    },
                ]}
                onSubmit={onCreate}
                submitTitle={"新增"}
            />}

            <Box
                width={"100%"}
                style={{marginTop: "30px"}}
            >
                <DataGridPro
                    filterModel={filterModel}
                    onFilterModelChange={(filterModel) =>
                    {
                        setFilterModel(filterModel);
                    }}
                    style={{borderTopLeftRadius: "unset"}}
                    localeText={DataGridLocale}
                    autoHeight={true}
                    loading={false}
                    rows={order?.invoices ?? []}
                    columns={columns}
                />
            </Box>
        </>
    );
};

export default InvoiceInOrderPage;
