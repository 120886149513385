import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

export default ({ children, onConfirm, disabled=false, disableTitle='' }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle>
                    <Typography variant="h4" align="center">
                        {`確定要${children}嗎？`}
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={async () => {
                            setOpen(false);
                            onConfirm();
                        }}
                    >
                        確定
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => setOpen(false)}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                disabled={disabled}
            >
                {disabled ? disableTitle : children}
            </Button>
        </>
    );
};
