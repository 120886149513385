import {DataGridPro} from "@mui/x-data-grid-pro"
import React, {useCallback} from "react"
import CreateView from "../component/CreateView";
import {InputType} from "../common/FormRenderer";
import {
    OrderStatus, ReviewStatus,
    useCreateOrderScheduleMutation, useDeleteOrderScheduleMutation,
    useGetUsersQuery, useUpdateOrderMutation,
    useUpdateOrderScheduleMutation,
} from "../generated/graphql";
import {format} from "date-fns";
import NotificationPopup from "../component/NotificationPopup";
import MutationPopupButton from "../component/MutationPopupButton";
import {omit} from "lodash";
import {DataGridLocale, selectMapFn} from "../common/Utils";
import {Box, Grid} from "@mui/material";
import ExportOrderScheduleButton from "./ExportOrderScheduleButton";
import ConditionalWrapper from "../common/ConditionalWrapper";
import ConfirmButton from "../component/ConfirmButton";
import {OrderScheduleItemList} from "../common/Constant";


export default ({order}) =>
{
    const {data} = useGetUsersQuery();
    const [createOrderSchedule] = useCreateOrderScheduleMutation();
    const [updateOrderSchedule] = useUpdateOrderScheduleMutation();
    const [removeOrderSchedule] = useDeleteOrderScheduleMutation();
    const [updateOrder] = useUpdateOrderMutation();
    const updateOrderFn = async (updatedOrder) =>
    {
        await updateOrder({
            variables: {
                id: order.id,
                order: updatedOrder,
            },
            refetchQueries: ['getOrder', 'getOrders'],
        });
    }
    const onUpdate = useCallback(
        async (orderSchedule, {resetForm}, setOpen) =>
        {
            try {
                await updateOrderSchedule({
                    variables: {
                        id: orderSchedule.id,
                        orderSchedule: {...omit(orderSchedule, "id"), orderId: order.id},
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success(`修改完成`);
                await updateOrderFn({scheduleReviewStatus: ReviewStatus.Started});
            } catch (e) {
                NotificationPopup.error(`發生錯誤 ${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateOrderSchedule]
    );
    const onCreate = useCallback(
        async (orderSchedule, {resetForm}, setOpen) =>
        {
            try {
                await createOrderSchedule({
                    variables: {
                        orderSchedule: {...orderSchedule, orderId: order.id},
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success(`新增成功`);
                await updateOrderFn({scheduleReviewStatus: ReviewStatus.Started});
            } catch (e) {
                NotificationPopup.error(`發生問題 ${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createOrderSchedule]
    );
    const onRemove = useCallback(
        async (orderScheduleId) =>
        {
            try {
                await removeOrderSchedule({
                    variables: {
                        id: orderScheduleId,
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success("刪除成功");
                await updateOrderFn({scheduleReviewStatus: ReviewStatus.Started});
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            }
        },
        [removeOrderSchedule]
    );
    const columns = [
        {name: "unit", label: "負責單位", flex: 1, required: true},
        {
            name: "content",
            label: "項目",
            type: InputType.autoComplete,
            options: OrderScheduleItemList,
            required: true,
            freeSolo: true,
        },
        {
            name: "startDate",
            label: "開始日期",
            required: true,
            type: InputType.date,
        },
        {
            name: "endDate",
            label: "結束日期",
            required: true,
            type: InputType.date,
        },
        {
            name: "assigneeId",
            label: "負責人",
            type: InputType.select,
            options: data?.users?.map(selectMapFn) ?? [],
        },
        {
            name: "notifyCustomer",
            label: "通知客戶",
            type: InputType.checkbox,
        },
        {
            name: "notifySales",
            label: "通知業務",
            type: InputType.checkbox,
        },
    ];
    return <>

        <Grid container spacing={2} alignItems={"center"}>

            <Grid item>
                <ConfirmButton onConfirm={async ()=>{
                    await updateOrderFn({scheduleReviewStatus: ReviewStatus.Reviewing});
                }}
                               disabled={order.scheduleReviewStatus != ReviewStatus.Started}
                               disableTitle={order.scheduleReviewStatus == ReviewStatus.Reviewing ? '審核中' : '已審核'}
                >
                    送審時程表
                </ConfirmButton>
            </Grid>
            <Grid item>
                <CreateView
                    title={"新增時程"}
                    columns={columns}
                    onSubmit={onCreate}
                />
            </Grid>
            <Grid item>
                <ExportOrderScheduleButton order={order}></ExportOrderScheduleButton>
            </Grid>
        </Grid>
        <Box
            width={"100%"}
            style={{marginTop: "30px"}}
        >
            <DataGridPro
                autoHeight
                sortModel={[{field: "startDate", sort: 'asc'}]}
                rows={order.orderSchedules}
                localeText={DataGridLocale}
                columns={[
                    {field: 'unit', headerName: '負責單位'},
                    {field: 'content', headerName: '項目', flex: 1},
                    {
                        field: 'startDate',
                        headerName: '開始日期',
                        valueFormatter: params => format(new Date(params?.value), "yyyy-MM-dd")
                    },
                    {
                        field: 'endDate',
                        headerName: '結束日期',
                        valueFormatter: params => format(new Date(params?.value), "yyyy-MM-dd")
                    },
                    {
                        field: 'assignee',
                        headerName: '負責人',
                        valueGetter: params => params?.value?.name,
                    },
                    {
                        field: 'notifyCustomer',
                        headerName: '通知客戶',
                        valueGetter: params => params?.value ? "O" : "X",
                    },
                    {
                        field: 'notifySales',
                        headerName: '通知業務',
                        valueGetter: params => params?.value ? "O" : "X",
                    },
                    {
                        field: 'actions', headerName: '修改', renderCell: ({row}) =>
                        {
                            return <>
                                <MutationPopupButton
                                    title={'修改'}
                                    columns={[
                                        {
                                            name: 'id',
                                            label: 'ID',
                                            value: row.id,
                                            type: InputType.hidden,
                                        },
                                        {
                                            name: 'unit',
                                            label: '負責單位',
                                            value: row.unit,
                                        },
                                        {
                                            name: 'content',
                                            label: '項目',
                                            value: row.content,
                                        },
                                        {
                                            name: "startDate",
                                            label: "開始日期",
                                            required: true,
                                            type: InputType.date,
                                            value: format(new Date(row.startDate), 'yyyy-MM-dd'),
                                        },
                                        {
                                            name: "endDate",
                                            label: "結束日期",
                                            required: true,
                                            type: InputType.date,
                                            value: format(new Date(row.endDate), 'yyyy-MM-dd'),
                                        },
                                        {
                                            name: "assigneeId",
                                            label: "負責人",
                                            type: InputType.select,
                                            options: data?.users?.map(selectMapFn) ?? [],
                                            value: row.assignee?.id,
                                        },
                                        {
                                            name: "notifyCustomer",
                                            label: "通知客戶",
                                            type: InputType.checkbox,
                                            value: row.notifyCustomer,
                                        },
                                        {
                                            name: "notifySales",
                                            label: "通知業務",
                                            type: InputType.checkbox,
                                            value: row.notifySales,
                                        }
                                    ]}
                                    onSubmit={onUpdate}
                                    submitTitle={'修改'}
                                />
                            </>
                        },
                    },
                    {
                        field: 'delete',
                        headerName: '刪除',
                        sortable: false,
                        disableColumnMenu: true,
                        renderCell: (params) => (
                            <ConfirmButton
                                onConfirm={() => onRemove(params.row.id)}
                            >
                                刪除
                            </ConfirmButton>
                        ),
                    },
                ]}

            />
        </Box>
    </>
}
