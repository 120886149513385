import React, { useCallback, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useCreateContactMutation, useUpdateContactMutation } from '../generated/graphql';
import { Box, Container } from "@mui/material";
import { DataGridLocale } from "../common/Utils";
import Page from "../component/Page";
import MutationPopupButton from "../component/MutationPopupButton";
import { InputType } from "../common/FormRenderer";
import {omit} from "lodash";
import NotificationPopup from "../component/NotificationPopup";

const ContactPage = ({ customer }) => {
    const [createContact] = useCreateContactMutation();
    const [updateContact] = useUpdateContactMutation();
    const onCreate = useCallback(
        async (contact, { resetForm }, setOpen) => {
            try {
                await createContact({
                    variables: {
                        contact: { ...contact, customerId: customer.id },
                    },
                    refetchQueries: ["getCustomer"],
                });
                NotificationPopup.success("建立成功");
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createContact]
    );
    const onUpdate = useCallback(
        async (contact, { resetForm }, setOpen) => {
            try {
                await updateContact({
                    variables: {
                        id: contact.id,
                        contact: omit(contact, "id"),
                    },
                    refetchQueries: ['getCustomer'],
                });
                NotificationPopup.success("修改完成");
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateContact]
    );
    const [filterModel, setFilterModel] = useState<any>({ items: [] });
    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "name", headerName: "聯絡窗口中文姓名", width: 150 },
        { field: "englishName", headerName: "聯絡窗口英文姓名", width: 150 },
        { field: 'title', headerName: '職稱', flex: 1 },
        { field: "email", headerName: "Email", flex: 1 },
        { field: "phone", headerName: "電話", flex: 1 },
        { field: "mobile", headerName: "手機", flex: 1 },
        { field: "lineId", headerName: "Line ID", flex: 1 },
        {
            field: 'edit',
            headerName: '修改',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: any) => (
                <MutationPopupButton
                    title={'修改'}
                    columns={[
                        {
                            name: "id",
                            label: "ID",
                            value: params?.row.id,
                            type: InputType.hidden,
                        },
                        {
                            name: "name",
                            label: "聯絡窗口中文姓名",
                            value: params.row.name,
                            required: true,
                        },
                        {
                            name: "englishName",
                            label: "聯絡窗口英文姓名",
                            value: params.row.englishName,
                            required: true,
                        },
                        { name: 'title', label: '職稱', value: params.row.title, required: true, },
                        {
                            name: "email",
                            label: "Email",
                            value: params.row.email,
                            required: true,
                        },
                        {
                            name: "phone",
                            label: "電話",
                            value: params.row.phone,
                            required: true,
                        },
                        {
                            name: "mobile",
                            label: "手機",
                            value: params.row.mobile,
                            required: true,
                        },
                        {
                            name: "lineId",
                            label: "Line ID",
                            value: params.row.lineId,
                            required: true,
                        },
                        {
                            name: "brandIds",
                            label: "負責品牌",
                            value: params?.row.brands?.map((brand) => brand.id) || [],
                            type: InputType.select,
                            options: customer?.brands?.map((brand) => ({
                                value: brand.id,
                                label: brand.name,
                            })) || [],
                            isMulti: true,
                            required: true,
                        },
                    ]}
                    description={"如尚無資料填入需填寫原因"}
                    onSubmit={onUpdate}
                    submitTitle={'修改'}
                />
            ),
        },
    ];

    return (
        <Page title={"Contact"}>
            <Container maxWidth={false} style={{ padding: "30px" }}>
                <MutationPopupButton
                    title={"新增聯絡人"}
                    columns={[
                        {
                            name: "name",
                            label: "聯絡窗口中文姓名",
                            required: true,
                        },
                        {
                            name: "englishName",
                            label: "聯絡窗口英文姓名",
                            required: true,
                        },
                        { name: 'title', label: '職稱', required: true, },
                        {
                            name: "email",
                            label: "Email",
                            required: true,
                        },
                        {
                            name: "phone",
                            label: "電話",
                            required: true,
                        },
                        {
                            name: "mobile",
                            label: "手機",
                            required: true,
                        },
                        {
                            name: "lineId",
                            label: "Line ID",
                            required: true,
                        },
                    ]}
                    description={"如尚無資料填入需填寫原因"}
                    onSubmit={onCreate}
                    submitTitle={"新增"}
                />

                <Box
                    width={"100%"}
                    style={{ marginTop: "30px" }}
                >
                    <DataGridPro
                        filterModel={filterModel}
                        onFilterModelChange={(filterModel) => {
                            setFilterModel(filterModel);
                        }}
                        style={{ borderTopLeftRadius: "unset" }}
                        localeText={DataGridLocale}
                        autoHeight={true}
                        loading={false}
                        rows={customer?.contacts ?? []}
                        columns={columns}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default ContactPage;

