import {CircularProgress} from "@mui/material";


export default ({loading,error,children})=>
{
    if(loading)
    {
        return <CircularProgress/>;
    }
    else if(error) {
        return <p style={{color: "red"}}>
            Encountered an error.
            <br/>
            {error.message}
        </p>
    }
    return children;
}
