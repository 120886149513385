import React, { useCallback, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
    useCreateBrandMutation,
    useGetUsersQuery,
    useRemoveBrandMutation,
    useUpdateBrandMutation
} from '../generated/graphql';
import { Box, Container } from "@mui/material";
import {DataGridLocale, selectMapFn} from "../common/Utils";
import Page from "../component/Page";
import MutationPopupButton from "../component/MutationPopupButton";
import { InputType } from "../common/FormRenderer";
import {omit} from "lodash";
import NotificationPopup from "../component/NotificationPopup";

export const BrandPage = ({ customer }) =>
{
    const {data} = useGetUsersQuery();
    const [createBrand] = useCreateBrandMutation();
    const [updateBrand] = useUpdateBrandMutation();
    const [removeBrand] = useRemoveBrandMutation();
    const onCreate = useCallback(
        async (brand, {resetForm}, setOpen) =>
        {
            try {
                await createBrand({
                    variables: {
                        brand: {...brand, customerId: customer.id},
                    },
                    refetchQueries: ["getCustomer"],
                });
                NotificationPopup.success("建立成功");
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createBrand]
    );
    const onUpdate = useCallback(
        async (brand, {resetForm}, setOpen) =>
        {
            try {
                await updateBrand({
                    variables: {
                        id: brand.id,
                        brand: omit(brand, "id"),
                    },
                    refetchQueries: ['getCustomer'],
                });
                NotificationPopup.success("修改完成");
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateBrand]
    );
    const onRemove = useCallback(
        async (brandId) =>
        {
            try {
                await removeBrand({
                    variables: {
                        id: brandId,
                    },
                    refetchQueries: ['getCustomer'],
                });
                NotificationPopup.success("刪除成功");
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            }
        },
        [removeBrand]
    );
    const [filterModel, setFilterModel] = useState<any>({items: []});
    const columns = [
        {field: "id", headerName: "ID", hide: true},
        {field: "name", headerName: "中文品牌"},
        {field: "englishName", headerName: "英文品牌"},
        {field: "contacts", headerName: "聯絡窗口",
            valueFormatter: ({value})=>(value.map((contact)=>(contact.name)).join(","))},
        {field: "assignee", headerName: "負責業務", valueGetter: params => params?.value?.name},
        {
            field: "edit",
            headerName: "修改",
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) => (
                <MutationPopupButton
                    title={"修改"}
                    columns={[
                        {
                            name: "id",
                            label: "ID",
                            value: params?.row.id,
                            type: InputType.hidden,
                        },
                        {
                            name: "name",
                            label: "中文品牌",
                            value: params.row.name,
                        },
                        {
                            name: "englishName",
                            label: "英文品牌",
                            value: params.row.englishName,
                        },
                        {
                            name: "contactIds",
                            label: "聯絡窗口",
                            type: InputType.select,
                            value: params?.row.contacts?.map((contact) => contact.id) || [],
                            options: customer.contacts?.map((contact) => ({ value: contact.id, label: contact.name })) ?? [],
                            isMulti: true,
                        },
                        {
                            name: "assigneeId",
                            label: "負責人",
                            type: InputType.select,
                            options: data?.users?.map(selectMapFn) ?? [],
                            value: params?.row.assignee?.id,
                        },
                    ]}
                    onSubmit={onUpdate}
                    submitTitle={"修改"}
                    onRemove={()=>onRemove(params?.row.id)}
                />
            ),
        },
    ];

    return (
        <Page title={"Brand"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <MutationPopupButton
                    title={"新增品牌"}
                    columns={[
                        {
                            name: "name",
                            label: "中文品牌",
                        },
                        {
                            name: "englishName",
                            label: "英文品牌",
                        },
                        {
                            name: "assigneeId",
                            label: "負責業務",
                            type: InputType.select,
                            options: data?.users?.map(selectMapFn) ?? [],
                        },
                    ]}
                    onSubmit={onCreate}
                    submitTitle={"新增"}
                />

                <Box width={"100%"} style={{marginTop: "30px"}}>
                    <DataGridPro
                        filterModel={filterModel}
                        onFilterModelChange={(filterModel) =>
                        {
                            setFilterModel(filterModel);
                        }}
                        style={{borderTopLeftRadius: "unset"}}
                        localeText={DataGridLocale}
                        autoHeight={true}
                        loading={false}
                        rows={customer?.brands ?? []}
                        columns={columns}
                    />
                </Box>
            </Container>
        </Page>
    );
}
