import React, {useEffect, useState} from "react";
import {Outlet, RouteObject} from "react-router";
import {makeStyles} from '@mui/styles';
import MenuBar from "./layout/MenuBar"
import {LicenseInfo} from '@mui/x-data-grid-pro';
import {ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, useQuery} from "@apollo/client";
import {getAccessToken, logout, setAccessToken} from "./auth/Auth";
import {Box, Grid} from "@mui/material";
import Login from "./auth/Login";
import {onError} from "@apollo/client/link/error";
// import Logo from "./image/logo.png";
import theme from "./theme/Theme";
import CustomerPage from "./customers/CustomerPage";
import CustomerDetail from "./customers/CustomerDetail";
import UserPage from "./users/UserPage";
import OrderPage from "./orders/OrderPage";
import OrderDetail from "./orders/OrderDetail";
import InvoicePage from "./invoices/InvoicePage";
import {InvoiceDetail} from "./invoices/InvoiceDetail";
import InterviewPage from "./interviews/InterviewPage";
import InterviewDetail from "./interviews/InterviewDetail";
import OrderItemPage from "./order-items/OrderItemPage";
import {OrderItemDetail} from "./order-items/OrderItemDetail";
import TermPage from "./terms/TermPage";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: theme.palette.background.default,/**/
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 200,
        },
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    content: {
        flex: "1 1 auto",
        height: "100%",
        overflow: "auto",
    },
}));

LicenseInfo.setLicenseKey("506c8b634c21c0e393ef7ee25b623380Tz03NDkzNSxFPTE3MjY3MTE3NDgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const MainLayout = () =>
{
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [accessToken, setStateAccessToken] = useState(getAccessToken());
    if (accessToken == null) {
        return (
            <div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center'}}>

                <img src={"/big_logo.jpg"} style={{width: 768/1.5, height: 297/1.5, marginBottom: 20}}/>
                <Grid display={"flex"}>
                    {/*<img src={Logo} style={{width: 50, height: 50, marginRight:20}}/>*/}
                    <Login
                        onSuccess={(accessToken:any) =>
                        {
                            setAccessToken(accessToken);
                            setStateAccessToken(accessToken);
                        }}
                    />
                </Grid>
            </div>
        );
    }
    const errorLink = onError(({graphQLErrors, networkError}) =>
    {
        if (graphQLErrors)
            graphQLErrors.forEach(({message, locations, path}) =>
            {
                if (message === "Unauthorized") {
                    logout();
                    setStateAccessToken(null);

                }
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                )
            });
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });
    console.log("login")
    const client = new ApolloClient({
        cache: new InMemoryCache({addTypename: false}),
        link: ApolloLink.from([errorLink, new HttpLink({
            uri: `${process.env.REACT_APP_BACKEND_URI}/graphql`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })]),
    });

    return (
        <ApolloProvider client={client}>
            <div className={classes.root}>
                <MenuBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
                <div className={classes.wrapper}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </div>
        </ApolloProvider>
    );
}
const routes: Partial<RouteObject>[] = [
    {
        path: "/",
        element: <MainLayout/>,
        children: [
            { path: "customers", element: <CustomerPage/> },
            { path: "customers/:id", element: <CustomerDetail/> },
            { path: "users", element: <UserPage/>},
            { path: "orders", element: <OrderPage/>},
            { path: "orders/:id", element: <OrderDetail/>},
            { path: "invoices", element: <InvoicePage/>},
            { path: "invoices/:id", element: <InvoiceDetail/>},
            { path: "interviews", element: <InterviewPage/>},
            { path: "interviews/:id", element: <InterviewDetail/>},
            { path: "order-items", element: <OrderItemPage/>},
            { path: "order-items/:id", element: <OrderItemDetail/>},
            { path: "terms", element: <TermPage/>},
        ]
    },
];

export default routes;
