import { Button, CircularProgress } from "@mui/material";
import NotificationPopup from "./NotificationPopup";
import {useEffect, useState} from "react";
import {getAccessToken} from "../auth/Auth";

const uploadFile = async (uriPart: string, files: File[], onSuccess) => {
    const token = await getAccessToken();
    const formData = new FormData();
    files.forEach(file=>
    {
        formData.append("files", file,file.name);
    });
    const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}${uriPart}`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        }
    );
    if (res.ok) {
        NotificationPopup.success(`上傳成功`);
        res.json().then((res) => {
            onSuccess(res);
        })
    } else {
        res.json().then((res) => NotificationPopup.error(`上傳遇到問題. ${res?.message}`)).catch(()=>NotificationPopup.error(`上傳遇到問題.`));
        console.error(
            `failed to upload file. statustext: ${res.statusText} status: ${res.status}`
        );
    }
};

export default ({  onSuccess, uriPart, uploadTitle}) => {
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        if (files.length>0) {
            const upload = async () => {
                setLoading(true);
                await uploadFile(uriPart, files, onSuccess);
                setLoading(false);
                setFiles([]);
            }
            upload();
        }
    }, [files])
    return (
        <div>
            {!loading && (
                <Button
                    variant={"contained"}
                    color={"primary"}
                    component={"label"}
                    htmlFor={uriPart}
                >
                    {uploadTitle}
                </Button>
            )}
            {loading && <CircularProgress />}
            <br />
            {files.length>0 && (
                <div>
                    {files.map(file=><p>已選擇檔案: {file.name}</p>)}
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={async () => {
                        }}
                    >
                        上傳
                    </Button>
                </div>
            )}

            <input
                style={{ display: "none" }}
                id={uriPart}
                type={"file"}
                multiple={true}
                onChange={(e) => {
                    const files = e.target.files;
                    const fileList:File[] = [];
                    for(let i = 0;i < (files?.length || 0);i++)
                    {
                        if(files && files[i])
                        {
                            fileList.push(files[i]);
                        }
                    }
                    setFiles(fileList);
                }}
            />
        </div>
    );
};
