import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DataGridPro, GridValueGetterParams} from '@mui/x-data-grid-pro';
import {useCreateInterviewMutation, useGetCustomersQuery, useGetInterviewsQuery} from '../generated/graphql';
import {Container, Box, Button} from '@mui/material';
import {DataGridLocale, selectMapFn} from '../common/Utils';
import Page from '../component/Page';
import MutationPopupButton from '../component/MutationPopupButton';
import {InputType} from "../common/FormRenderer";
import NotificationPopup from "../component/NotificationPopup";
import {BudgetLevelList, CooperationChanceList, NextStepList} from "../common/Constant";

const InterviewPage = () =>
{
    const navigate = useNavigate();
    const {data, loading, error} = useGetInterviewsQuery();
    const [createInterview] = useCreateInterviewMutation();
    const { data: customerData, loading: customerLoading } = useGetCustomersQuery();

    const [filterModel, setFilterModel] = useState<any>({items: []});

    const columns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: 'productName', headerName: '產品名稱', flex: 1},
        {field: 'budget', headerName: '預算', flex: 1},
        {field: 'targetAudience', headerName: '目標客群', flex: 1},
        {field: 'salesChannel', headerName: '銷售渠道', flex: 1},
        {field: 'competitor', headerName: '競爭對手', flex: 1},
        {field: 'cooperationContent', headerName: '合作內容', flex: 1},
        {field: 'createdAt', headerName: '建立時間', flex: 1},
        {field: 'updatedAt', headerName: '更新時間', flex: 1},
        {
            field: 'detail',
            headerName: '查看',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: any) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/interviews/${params.id}`)}
                >
                    查看
                </Button>
            ),
        },
    ];
    const onCreate = useCallback(
        async (interview, { resetForm }, setOpen) => {
            try {
                await createInterview({
                    variables: {
                        interview: interview,
                    },
                    refetchQueries: ['getInterviews'],
                });
                NotificationPopup.success('建立成功');
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createInterview]
    );
    const customerOptions = customerData?.customers.map(selectMapFn);
    console.log(customerData);
    console.log(data);
    return (
        <Page title={'Interview'}>
            <Container maxWidth={false} style={{padding: '30px'}}>
                <MutationPopupButton
                    title={'新增訪談表'}
                    columns={[
                        {
                            name: 'customerId',
                            label: '客戶名稱',
                            type: InputType.select,
                            options: customerOptions,
                        },
                        {
                            name: 'productName',
                            label: '產品名稱',
                            type: InputType.text,
                        },
                        {
                            name: 'productFeatures',
                            label: '產品主要特色',
                            type: InputType.textarea,
                        },
                        {
                            name: 'salesChannel',
                            label: '產品銷售通路',
                            type: InputType.text,
                        },
                        {
                            name: 'competitor',
                            label: '產品競品',
                            type: InputType.text,
                        },
                        {
                            name: 'targetAudience',
                            label: '目標客群(TA)',
                            type: InputType.text,
                        },
                        {
                            name: 'influencerRecords',
                            label: '監播紀錄(有口碑文才要填)',
                            type: InputType.text,
                        },
                        {
                            name: 'pastCooperations',
                            label: '過往有無合作過類似內容及規格',
                            type: InputType.textarea,
                        },
                        {
                            name: 'cooperationAndIssues',
                            label: '與哪家合作 有遇到什麼問題',
                            type: InputType.textarea,
                        },
                        {
                            name: 'demandReason',
                            label: '為什麼會有這個需求',
                            type: InputType.textarea,
                        },
                        {
                            name: 'measurementCriteria',
                            label: '該如何衡量這個需求被滿足',
                            type: InputType.textarea,
                        },
                        {
                            name: 'interviewStrategy',
                            label: '訪談策略',
                            type: InputType.textarea,
                        },
                        {
                            name: 'customerFeedback',
                            label: '客戶回饋',
                            type: InputType.textarea,
                        },
                        {
                            name: 'nextSteps',
                            label: '下一步策略',
                            type: InputType.select,
                            options: NextStepList,
                            isMulti: true,
                            value: [],
                        },
                        {
                            name: 'nextStepOther',
                            label: '其他下一步策略',
                            type: InputType.text,
                        },
                        {
                            name: 'budgetLevel',
                            label: '預估預算等級',
                            type: InputType.select,
                            options: BudgetLevelList,
                        },
                        {
                            name: 'cooperationChance',
                            label: '合作機率預估',
                            type: InputType.select,
                            options: CooperationChanceList,
                        },
                    ]}
                    onSubmit={onCreate}
                    submitTitle={'新增'}
                />

                <Box width={'100%'} style={{marginTop: '30px', height: '100vh'}}>
                    <DataGridPro
                        filterModel={filterModel}
                        onFilterModelChange={(filterModel) => setFilterModel(filterModel)}
                        style={{borderTopLeftRadius: 'unset'}}
                        localeText={DataGridLocale}
                        autoHeight={true}
                        loading={loading}
                        rows={data?.interviews ?? []}
                        columns={columns}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default InterviewPage;
