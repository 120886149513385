import React from 'react';
import './App.css';
import {useGetUsersQuery} from "./generated/graphql";
import {range} from "lodash";
import {Container} from "@mui/material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Outlet, useRoutes} from "react-router-dom";
import {ThemeProvider, Box, Grid} from "@mui/material";
import {ErrorBoundary} from "./common/ErrorBoundary";
import routes from "./routes";
import {makeStyles} from "@mui/styles";
import theme from "./theme/Theme";

const Inner = () => {

    const routing = useRoutes(routes);
    return routing;
}
const useStyles = makeStyles((theme: any) => ({

}))

const App = () => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <Inner/>
            </ErrorBoundary>
        </ThemeProvider>
    );
};

export default App;
