import Page from "../component/Page";
import {useParams} from "react-router";
import {
    useCreateMediaItemMutation,
    useGetOrderItemQuery,
    useRemoveMediaItemMutation,
    useRemoveOrderItemMutation,
    useUpdateMediaItemMutation,
    useUpdateOrderItemMutation
} from "../generated/graphql";
import {Grid} from "@mui/material";
import {InputType} from "../common/FormRenderer";
import MutationPopupButton from "../component/MutationPopupButton";
import NotificationPopup from "../component/NotificationPopup";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {DataGridLocale} from "../common/Utils";
import ConfirmButton from "../component/ConfirmButton";

export const OrderItemDetail = ({myOrderItem, canEdit=true}) => {
    const { id: idString } = useParams<string>();
    const id = idString ? parseInt(idString) : 0;
    const { loading, error, data } = useGetOrderItemQuery({
        variables: { id },
    });
    const orderItem = myOrderItem ?? data?.orderItem;
    const [updateOrderItem] = useUpdateOrderItemMutation();
    const [createMediaItem] = useCreateMediaItemMutation();
    const [updateMediaItem] = useUpdateMediaItemMutation();
    const updateOrderItemFn = async (formData, { resetForm }, setOpen) => {
        try {
            await updateOrderItem({
                variables: {
                    id: orderItem!.id,
                    orderItem: {
                        name: formData.name,
                    },
                },
                refetchQueries: ["getOrderItem"],
            });
            NotificationPopup.success("修改成功");
        } catch (error) {
            NotificationPopup.error(`發生問題：${error.message}`);
        } finally {
            resetForm();
            setOpen(false);
        }
    };
    const createMediaItemFn = async (mediaItem, { resetForm }, setOpen) => {
        try {
            await createMediaItem({
                variables: {
                    mediaItem: {
                        orderItemId: orderItem?.id,
                        name: mediaItem.name,
                        content: mediaItem.content,
                        count: mediaItem.count,
                    },
                },
                refetchQueries: ["getOrderItem", "getOrder"],
            });
            NotificationPopup.success("新增成功");
        } catch (error) {
            NotificationPopup.error(`發生問題：${error.message}`);
        } finally {
            resetForm();
            setOpen(false);
        }
    };
    const [removeOrderItem] = useRemoveOrderItemMutation();

    const onRemove = async () => {
        try {
            await removeOrderItem({
                variables: {
                    id: orderItem?.id,
                },
                refetchQueries: ["getOrder", "getOrderItem", "getOrderItems"], // 根据需要进行调整，重新查询相关数据
            });
            NotificationPopup.success("刪除成功");
        } catch (error) {
            NotificationPopup.error(`發生問題：${error.message}`);
        }
    };
    const [removeMediaItem] = useRemoveMediaItemMutation();

    const handleMediaItemDelete = async (mediaItemId) => {
        try {
            await removeMediaItem({
                variables: {
                    id: mediaItemId,
                },
                refetchQueries: ["getOrder", "getOrderItem", "getOrderItems"], // 根據需要重新查詢相關資料
            });
            NotificationPopup.success("刪除成功");
        } catch (error) {
            NotificationPopup.error(`發生問題：${error.message}`);
        }
    };

    const mediaItemsColumns = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'name', headerName: '項目', width: 200 },
        { field: 'content', headerName: '內容', flex:1,
            renderCell: params => (
                <pre>{params.row.content}</pre>
            )},
        { field: 'count', headerName: '數量' },
        {
            field: 'edit',
            headerName: '修改',
            sortable: false,
            disableColumnMenu: true,
            hide: !canEdit,
            renderCell: (params) => (
                <MutationPopupButton
                    title="修改"
                    columns={[
                        {
                            name: "id",
                            label: "ID",
                            value: params?.row.id,
                            type: InputType.hidden,
                        },
                        {
                            name: 'name',
                            label: '項目',
                            value: params.row.name,
                        },
                        {
                            name: 'content',
                            label: '內容',
                            value: params.row.content,
                            type: InputType.textarea,
                        },
                        {
                            name: 'count',
                            label: '數量',
                            value: params.row.count,
                        },
                    ]}
                    onSubmit={updateMediaItemFn}
                    submitTitle="修改"
                />
            ),
        },
        {
            field: 'delete',
            headerName: '刪除',
            sortable: false,
            hide: !canEdit,
            disableColumnMenu: true,
            renderCell: (params) => (
                <ConfirmButton
                    onConfirm={() => handleMediaItemDelete(params.row.id)}
                >
                    刪除
                </ConfirmButton>
            ),
        }
    ];
    const updateMediaItemFn = async (formData, { resetForm }, setOpen) => {
        try {
            await updateMediaItem({
                variables: {
                    id: formData.id,
                    mediaItem: {
                        name: formData.name,
                        content: formData.content,
                        count: formData.count,
                    },
                },
                refetchQueries: ['getOrderItem'], // 根據您的需求進行調整，重新查詢相關資料
            });
            NotificationPopup.success("修改成功");
        } catch (error) {
            NotificationPopup.error(`發生問題：${error.message}`);
        } finally {
            resetForm();
            setOpen(false);
        }
    };

    return <Page title="專案項目">
        {/*<Container maxWidth="xl">*/}
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    專案名稱： {orderItem?.name}
                </Grid>
                <Grid item>
                    {canEdit && <MutationPopupButton
                        title="修改名稱"
                        columns={[
                            { name: "name", label: "專案名稱", value: orderItem?.name },
                        ]}
                        onSubmit={updateOrderItemFn}
                        submitTitle="修改"
                    />}
                </Grid>
                <Grid item>
                    {canEdit && <ConfirmButton onConfirm={onRemove}>
                        刪除
                    </ConfirmButton>}
                </Grid>
                <Grid item>
                    {canEdit && <MutationPopupButton
                        title="新增項目"
                        columns={[
                            {
                                name: "name",
                                label: "項目",
                            },
                            {
                                name: "content",
                                label: "內容",
                                type: InputType.textarea,
                            },
                            {
                                name: "count",
                                label: "數量",
                            },
                        ]}
                        onSubmit={createMediaItemFn}
                        submitTitle="新增"
                    />}
                </Grid>
            </Grid>
            <DataGridPro
                style={{ borderTopLeftRadius: "unset" }}
                localeText={DataGridLocale}
                autoHeight
                loading={false}
                rows={orderItem?.mediaItems ?? []}
                columns={mediaItemsColumns}
                getRowHeight={()=>"auto"}
            />
        {/*</Container>*/}
    </Page>
}