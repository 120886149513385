import {
    AppBar, Box,
    Button, Container,
    Grid,
    Link,
    Tab,
    Tabs
} from "@mui/material";
import React, {useCallback} from "react";
import Page from "../component/Page";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import TabPanel from "../common/TabPanel";
import {InvoiceStatus, Role, useGetInvoiceQuery, useUpdateInvoiceMutation} from "../generated/graphql";
import DetailView from "../component/DetailView";
import {useParams} from "react-router";
import {InvoiceStatusList, InvoiceStatusMap} from "../common/Constant";
import {NavLink} from "react-router-dom";
import UploadFile from "../component/UploadFile";
import {GridValueGetterParams} from "@mui/x-data-grid-pro";
import UpdateInvoiceButton from "./UpdateInvoiceButton";
import {HasRole, useTabIndex} from "../common/Utils";
import NotificationPopup from "../component/NotificationPopup";
import {InputType} from "../common/FormRenderer";
import CreateView from "../component/CreateView";
import {format} from "date-fns";
import MutationPopupButton from "../component/MutationPopupButton";
import ConfirmButton from "../component/ConfirmButton";

export const InvoiceDetail = () =>
{
    const {id: idString} = useParams();
    const id = idString ? parseInt(idString) : 0;
    const {loading, error, data} = useGetInvoiceQuery({
        variables: {id},
    });
    const [tabIndex, setTabIndex] = useTabIndex();
    const [updateInvoice] = useUpdateInvoiceMutation();

    const columns = [
        {field: "status", headerName: "狀態", valueFormatter: (value) => InvoiceStatusMap[value]},
        {field: "number", headerName: "發票號碼"},
        {field: "expectedPostDate", headerName: "請求開立日期"},
        {field: "postDate", headerName: "實際開立日期"},
        {field: "expectedPaidDate", headerName: "預期付款日期"},
        {field: "postDate", headerName: "實際開立日期"},
        {field: "amount", headerName: "金額"},
        {field: "title", headerName: "發票抬頭"},
        {field: "note", headerName: "備註"},
        {field: "order", headerName: "委刊單"},
    ];
    const updateColumns = [
        {
            name: 'title',
            label: '標題',
            value: data?.invoice.title,
        },
        {
            name: 'note',
            label: '備註',
            value: data?.invoice.note,
            type: InputType.textarea,
        },
        {
            name: 'expectedPostDate',
            label: '請求開立日期',
            value: data?.invoice.expectedPostDate ? format(new Date(data?.invoice.expectedPostDate), 'yyyy-MM-dd') : null,
            type: InputType.date,
        },
        {
            name: 'postDate',
            label: '開立日期',
            value: data?.invoice.postDate ? format(new Date(data?.invoice.postDate), 'yyyy-MM-dd') : null,
            type: InputType.date,
        },
        {
            name: 'expectedPaidDate',
            label: '預期付款日期',
            value: data?.invoice.expectedPaidDate ? format(new Date(data?.invoice.expectedPaidDate), 'yyyy-MM-dd') : null,
            type: InputType.date,
        },
        {
            name: 'paidDate',
            label: '實際付款日期',
            value: data?.invoice.paidDate ? format(new Date(data?.invoice.paidDate), 'yyyy-MM-dd') : null,
            type: InputType.date,
        },
        {
            name: 'amount',
            label: '金額',
            value: data?.invoice.amount,
            type: InputType.number,
        },
        {
            name: 'status',
            label: '狀態',
            type: InputType.select,
            options: InvoiceStatusList,
            value: data?.invoice.status,
        },
    ];

    const onSubmit = useCallback(
        async (updatedInvoice, {resetForm}, setOpen) =>
        {
            try {
                await updateInvoice({
                    variables: {
                        id,
                        invoice: updatedInvoice,
                    },
                    refetchQueries: ['getInvoice', 'getInvoices', 'getOrder'],
                });
                NotificationPopup.success(`修改完成`);
                setOpen(false);
            } catch (e) {
                NotificationPopup.error(`修改發票發生問題: ${e.message}`);
                console.error(e);
            }
        },
        [updateInvoice]
    );
    const updateInvoiceFn = async (invoice) =>
    {
        await updateInvoice({
            variables: {
                id,
                invoice,
            },
            refetchQueries: ['getInvoice', 'getInvoices', 'getOrder'],
        });
    }

    return (
        <Page title={"發票資料"}>
            <Box style={{padding: "30px"}}>
                <DefaultQueryHandler error={error} loading={loading}>
                    <HasRole roles={[Role.Finance]}>
                        <Grid container spacing={2}>
                            {(data?.invoice?.status === InvoiceStatus.Pending) &&
                            <Grid item>
                                <CreateView
                                    title={'修改發票'}
                                    columns={updateColumns}
                                    onSubmit={onSubmit}
                                    submitTitle={'修改'}
                                />
                            </Grid>}
                            {(data?.invoice?.status === InvoiceStatus.Pending) && (
                                <Grid item>
                                    <MutationPopupButton
                                        title="回填發票"
                                        columns={[
                                            {
                                                name: 'postDate',
                                                label: '開立日期',
                                                type: InputType.date,
                                                value: format(new Date(), 'yyyy-MM-dd'),
                                            },
                                            {
                                                name: 'number',
                                                label: '發票號碼',
                                                type: InputType.text,
                                                required: true,
                                            },
                                            {
                                                name: 'status',
                                                label: '狀態',
                                                type: InputType.hidden,
                                                value: InvoiceStatus.Posted,
                                            }
                                        ]}
                                        onSubmit={onSubmit}
                                        submitTitle="回填"
                                    />
                                </Grid>
                            )}
                            {(data?.invoice?.status === InvoiceStatus.Posted) &&
                                <MutationPopupButton
                                    title="回填付款"
                                    columns={[
                                        {
                                            name: 'paidDate',
                                            label: '付款日期',
                                            type: InputType.date,
                                            value: format(new Date(), 'yyyy-MM-dd'),
                                        },
                                        {
                                            name: 'status',
                                            label: '狀態',
                                            type: InputType.hidden,
                                            value: InvoiceStatus.Paid,
                                        }
                                    ]}
                                    onSubmit={onSubmit}
                                    submitTitle="回填"/>
                            }
                        </Grid>
                    </HasRole>
                    <Box width={"100%"} style={{marginTop: "10px"}}>
                        <DetailView data={data?.invoice} columns={columns}/>
                    </Box>
                </DefaultQueryHandler>
            </Box>
        </Page>
    )
}
