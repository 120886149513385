import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DataGridPro, GridSortModel} from '@mui/x-data-grid-pro';
import {useCreateCustomerMutation, useGetCustomersQuery} from '../generated/graphql';
import {Container, Box, Button} from "@mui/material";
import {DataGridLocale} from "../common/Utils";
import Page from "../component/Page";
import MutationPopupButton from "../component/MutationPopupButton";
import {InputType} from "../common/FormRenderer";
import {
    CustomerSourceList,
    CustomerSourceMap,
    CustomerTypeList,
    CustomerTypeMap, IndustryTypeList, IndustryTypeMap, RoleList
} from "../common/Constant";
import NotificationPopup from "../component/NotificationPopup";


const CustomerPage = () =>
{
    const navigate = useNavigate();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"desc"}]);
    const {data, loading, error} = useGetCustomersQuery();
    const [createCustomer] = useCreateCustomerMutation();
    const onCreate = useCallback(
        async (customer, { resetForm }, setOpen) => {
            try {
                await createCustomer({
                    variables: {
                        customer: customer,
                    },
                    refetchQueries: ["getCustomers"],
                });
                NotificationPopup.success("建立成功");
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createCustomer]
    );
    const [filterModel, setFilterModel] = useState<any>({items: []});
    const columns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: 'name', headerName: '名稱'},
        {field: 'phone', headerName: '電話'},
        {field: 'type', headerName: '客戶類型', valueOptions: CustomerTypeList, type: "singleSelect",
            valueGetter: (params)=>CustomerTypeMap[params.value]},
        {field: 'industryTypes', headerName: '產業類型',
            valueGetter: (params)=>params.value.map((industryType)=>IndustryTypeMap[industryType]).join(',')},
        {field: 'taxId', headerName: '統一編號'},
        {
            field: "source",
            headerName: "客戶來源",valueOptions: CustomerSourceList, type: "singleSelect",
            valueGetter: (params)=>CustomerSourceMap[params.value]
        },
        {
            field: 'detail',
            headerName: '查看',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/customers/${params.id}`)}
                >
                    查看
                </Button>
            ),
        },
    ];

    return (

        <Page title={"客戶列表"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <MutationPopupButton
                    title={"建立客戶"}
                    columns={[
                        {
                            name: "name",
                            label: "名稱",
                        },
                        {
                            name: "address",
                            label: "地址",
                        },
                        {
                            name: "industryTypes",
                            label: "產業類別",
                            value: [],
                            type: InputType.select,
                            options: IndustryTypeList,
                            isMulti: true,
                            maxCount: 3,
                        },
                        {
                            name: "phone",
                            label: "電話",
                        },
                        {
                            name: "taxId",
                            label: "統一編號",
                        },
                        {
                            name: "type",
                            label: "客戶類型",
                            type: InputType.select,
                            options: CustomerTypeList,
                        },
                        {
                            name: "source",
                            label: "客戶來源",
                            type: InputType.select,
                            options: CustomerSourceList,
                        },
                    ]}
                    onSubmit={onCreate}
                    submitTitle={"建立"}
                />

                <Box
                    width={"100%"}
                    style={{marginTop: "30px", height: "100vh"}}
                >
                    <DataGridPro
                        filterModel={filterModel}
                        onFilterModelChange={(filterModel) =>
                        {
                            setFilterModel(filterModel);
                        }}
                        style={{borderTopLeftRadius: "unset"}}
                        localeText={DataGridLocale}
                        autoHeight={true}
                        loading={loading}
                        rows={data?.customers ?? []}
                        columns={columns}
                        sortModel={sortModel}
                        onSortModelChange={(sortModel)=> {
                            setSortModel(sortModel);
                        }}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default CustomerPage;
