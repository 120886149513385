import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DataGridPro, GridSortModel, GridValueGetterParams} from '@mui/x-data-grid-pro';
import {useCreateOrderMutation, useGetCustomersQuery, useGetOrdersQuery} from '../generated/graphql';
import {Container, Box, Button} from '@mui/material';
import {DataGridLocale, selectMapFn} from '../common/Utils';
import Page from '../component/Page';
import MutationPopupButton from '../component/MutationPopupButton';
import {InputType} from "../common/FormRenderer";
import {find} from "lodash";
import CreateOrderButton from "./CreateOrderButton";
import {OrderStatusList, OrderStatusMap} from "../common/Constant";

const OrderPage = () =>
{
    const navigate = useNavigate();
    const {data, loading, error} = useGetOrdersQuery();

    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"id",sort:"desc"}]);
    const [filterModel, setFilterModel] = useState<any>({items: []});

    const columns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: 'number', headerName: '委刊單號', width: 200},
        {field: 'name', headerName: '名稱', flex: 1},
        {field: 'customer', headerName: '客戶', flex: 1,
            valueGetter: (params: GridValueGetterParams)=>params.row.customer?.name},
        {
            headerName: "狀態",
            type: "singleSelect",
            field: "status",
            valueGetter: (params: GridValueGetterParams) => {
                return OrderStatusMap[params.row.status as string];
            },
            valueOptions: params => OrderStatusList,
        },
        {
            field: 'detail',
            headerName: '查看',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: any) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/orders/${params.id}`)}
                >
                    查看
                </Button>
            ),
        },
    ];

    return (
        <Page title={'Order'}>
            <Container maxWidth={false} style={{padding: '30px'}}>
                <CreateOrderButton />
                <Box width={'100%'} style={{marginTop: '30px', height: '100vh'}}>
                    <DataGridPro
                        filterModel={filterModel}
                        onFilterModelChange={(filterModel) => setFilterModel(filterModel)}
                        style={{borderTopLeftRadius: 'unset'}}
                        localeText={DataGridLocale}
                        autoHeight={true}
                        loading={loading}
                        rows={data?.orders ?? []}
                        columns={columns}
                        sortModel={sortModel}
                        onSortModelChange={(sortModel)=> {
                            setSortModel(sortModel)
                        }}
                    />
                </Box>
            </Container>
        </Page>
    );
}

export default OrderPage;