import {useCallback, useState} from "react";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import {Simulate} from "react-dom/test-utils";
import CreateView from "../component/CreateView";
import {useCreateOrderMutation, useGetCustomersQuery, useGetTermsQuery, useGetUsersQuery} from "../generated/graphql";
import {InputType} from "../common/FormRenderer";
import {find, omit} from "lodash";
import {selectMapFn} from "../common/Utils";
import {useUser} from "../auth/Auth";
import NotificationPopup from "../component/NotificationPopup";

export default () => {
    const [customer, setCustomer] = useState<any>();
    const { data, loading, error } = useGetCustomersQuery();
    const userListQuery = useGetUsersQuery();
    const termsQuery = useGetTermsQuery();
    const [createOrder] = useCreateOrderMutation();
    const user = useUser();
    const [selectedTerm, setSelectedTerm] = useState<any>(null);
    const onCreate = useCallback(
        async (order, {resetForm}, setOpen) =>
        {
            try {
                await createOrder({
                    variables: {
                        order: {
                            ...omit(order, ["termId"]),
                            assigneeId: user?.id,
                            projectTerms: selectedTerm?.projectTerms,
                            termsConditions: selectedTerm?.termsConditions,
                        },
                    },
                    refetchQueries: ['getOrders'],
                });
                NotificationPopup.success(`委刊單建立完成`);
            } catch (e) {
                console.log(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createOrder, user, selectedTerm]
    );
    const handleTermSelect = (termId) => {
        const selectedTerm = termsQuery.data?.terms.find((term) => term.id === termId);
        setSelectedTerm(selectedTerm);
    };
    const columns = [
        { name: "name", label: "專案名稱", required: true },
        {
            name: "customerId",
            label: "客戶",
            type: InputType.autoComplete,
            options: data?.customers.map(selectMapFn),
            onChange: (d) => {
                setCustomer(find(data?.customers ?? [], (c)=>{
                    return c.id == d;
                }));
            },
        },
        {
            name: "brandId",
            label: "品牌",
            type: InputType.select,
            options: customer?.brands?.map(selectMapFn) ?? [],
            required: true,
        },
        {
            name: "contactId",
            label: "聯絡人",
            type: InputType.select,
            options: customer?.contacts?.map(
                    selectMapFn
                )
                ?? [],
            required: true,
        },
        {
            name: "termId",
            label: "條款",
            type: InputType.select,
            options: termsQuery.data?.terms.map((term) => ({ value: term.id, label: term.name })) ?? [],
            onChange: handleTermSelect,
            required: true,
        },
    ];
    return (
        <DefaultQueryHandler
            loading={
                loading && userListQuery.loading
            }
            error={error}
        >
            <CreateView
                title={"建立委刊單"}
                columns={columns}
                onSubmit={onCreate}
            />
        </DefaultQueryHandler>
    );
}