import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {
    useCreateProductItemMutation, useRemoveProductItemMutation, useUpdateProductItemMutation,
} from '../generated/graphql';
import {Box, Button, Container} from '@mui/material';
import {DataGridLocale, selectMapFn} from '../common/Utils';
import Page from '../component/Page';
import MutationPopupButton from '../component/MutationPopupButton';
import {InputType} from '../common/FormRenderer';
import {omit} from "lodash";
import NotificationPopup from "../component/NotificationPopup";
import ConfirmButton from "../component/ConfirmButton";

const ProductItemPage = ({order, canEdit=true}) =>
{
    const [createProductItem] = useCreateProductItemMutation();
    const [updateProductItem] = useUpdateProductItemMutation();
    const [removeProductItem] = useRemoveProductItemMutation();
    const onCreate = useCallback(
        async (productItem, {resetForm}, setOpen) =>
        {
            try {
                await createProductItem({
                    variables: {
                        productItem: {...productItem, orderId: order.id},
                    },
                    refetchQueries: ['getOrder', 'getOrderItems'],
                });
                NotificationPopup.success(`新增完成`);
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createProductItem]
    );
    const onUpdate = useCallback(
        async (productItem, {resetForm}, setOpen) =>
        {
            try {
                await updateProductItem({
                    variables: {
                        id: productItem.id,
                        productItem: omit(productItem, ["id"]),
                    },
                    refetchQueries: ['getOrder'],
                });
                NotificationPopup.success(`修改完成`);
            } catch (e) {
                NotificationPopup.error(`發生問題：${e.message}`);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateProductItem]
    );
    const onDelete = async (productItemId) => {
        try {
            await removeProductItem({
                variables: {
                    id: productItemId,
                },
                refetchQueries: ["getOrder"], // 根據需要重新查詢相關資料
            });
            NotificationPopup.success("刪除成功");
        } catch (error) {
            NotificationPopup.error(`發生問題：${error.message}`);
        }
    };
    const [filterModel, setFilterModel] = useState<any>({items: []});
    const columns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: 'title', headerName: '名稱'},
        {field: 'content', headerName: '內容', width: 300},
        {field: 'amount', headerName: '金額', type: 'number'},
        {
            field: 'edit',
            headerName: '修改',
            sortable: false,
            disableColumnMenu: true,
            hide: !canEdit,
            flex: 1,
            renderCell: (params: any) => (
                <MutationPopupButton
                    title={'修改'}
                    columns={[
                        {
                            name: 'id',
                            label: 'ID',
                            value: params?.row.id,
                            type: InputType.hidden,
                        },
                        {
                            name: 'title',
                            label: '名稱',
                            value: params.row.title,
                        },
                        {
                            name: 'content',
                            label: '內容',
                            value: params.row.content,
                        },
                        {
                            name: 'amount',
                            label: '金額',
                            value: params.row.amount,
                            type: InputType.number,
                        },
                    ]}
                    onSubmit={onUpdate}
                    submitTitle={'修改'}
                />
            ),
        },
        {
            field: 'delete',
            headerName: '刪除',
            sortable: false,
            hide: !canEdit,
            disableColumnMenu: true,
            renderCell: (params) => (
                <ConfirmButton
                    onConfirm={() => onDelete(params.row.id)}
                >
                    刪除
                </ConfirmButton>
            ),
        }
    ];

    return (
        <div style={{marginTop: 30}}>
            {canEdit && <MutationPopupButton
                title={'新增額外費用'}
                columns={[
                    {
                        name: 'title',
                        label: '名稱',
                    },
                    {
                        name: 'content',
                        label: '內容',
                    },
                    {
                        name: 'amount',
                        label: '金額',
                        type: InputType.number,
                    },
                ]}
                onSubmit={onCreate}
                submitTitle={'新增'}
            />}

            <Box width={'100%'} style={{marginTop: '30px'}}>
                <DataGridPro
                    filterModel={filterModel}
                    onFilterModelChange={(filterModel) =>
                    {
                        setFilterModel(filterModel);
                    }}
                    style={{borderTopLeftRadius: 'unset'}}
                    localeText={DataGridLocale}
                    autoHeight={true}
                    loading={false}
                    rows={order?.productItems ?? []}
                    columns={columns}
                />
            </Box>
        </div>
    );
};

export default ProductItemPage;
